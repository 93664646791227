import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import Swal from "sweetalert2";
import {FileUploader} from "react-drag-drop-files";
import AdminLayout from "./AdminLayout";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const fileTypes = ["JPG", "PNG", "JPEG"];
const AdminNewsLetter = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/socialMedia/getNewsLetterEmails`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "created_at",
      label: "Date",
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"News Letter"} data={data} columns={columns} options={options} />
      </div>
    </AdminLayout>
  );
};
export default AdminNewsLetter;
