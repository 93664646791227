import "react-bootstrap-drawer/lib/style.css";
import React, {useState} from "react";
import {Collapse} from "react-bootstrap";
import {Drawer} from "react-bootstrap-drawer";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import cookie from "js-cookie";
import axios from "axios";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {AdminSidebarData} from "../../Components/AdminSidebarData";
import allActions from "../../actions/allActions";
import {colors} from "../../helpers/colors";

const AdminSidebar = (props) => {
  const [open, setOpen] = useState(true);
  const handleToggle = () => setOpen(!open);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const SignOut = () => {
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Sign Out!",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_API}/auth/logout`)
          .then((res) => {
            axios.defaults.headers.common["Authorization"] = ``;
            dispatch(allActions.userActions.logOut());
            cookie.set("token", "");
            // this.props.setLogin(res.data.user);
            navigate("/");
          })
          .catch((e) => {
            toast("Error", {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          });
      }
    });
  };

  return (
    <Drawer {...props}>
      <Drawer.Toggle onClick={handleToggle} />
      <Collapse in={open}>
        <Drawer.Overflow>
          <Drawer.ToC>
            <Drawer.Header href="/">
              <div
                className="w-100 text-center"
                style={{color: colors.primaryColor, cursor: "pointer"}}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  src={`${process.env.REACT_APP_FILES_PATH}/images/darbet-m3allem-logo.jpg`}
                  alt="logo"
                  style={{width: "100px"}}
                />
                <hr />
              </div>
            </Drawer.Header>
            <Drawer.Nav style={{position: "relative"}}>
              {AdminSidebarData?.map((val, index) => {
                return (
                  <Drawer.Item key={index} href={`/#${val.link}`} backgroundColor="red">
                    {val.icon}{" "}
                    <span
                      style={
                        window.location.hash.substring(1) == val.link ? {color: colors.primaryColor} : null
                      }
                    >
                      {val.title}
                    </span>
                  </Drawer.Item>
                );
              })}
              <button
                className="btn btn-primary w-100"
                style={{
                  bottom: "-30px",
                  position: "absolute",
                  left: "0",
                }}
                onClick={SignOut}
              >
                Sign Out
              </button>
            </Drawer.Nav>
          </Drawer.ToC>
        </Drawer.Overflow>
      </Collapse>
    </Drawer>
  );
};

export default AdminSidebar;
