import React, {useState, useEffect} from "react";
import {Navbar, Nav} from "react-bootstrap";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import cookie from "js-cookie";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import allActions from "../../actions/allActions";

const AdminLogin = ({children, match}) => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const data = {email: form.email, password: form.password};
    axios
      .post(`${process.env.REACT_APP_API}/auth/login`, data)
      .then((res) => {
        const token = res.data.access_token;
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        dispatch(allActions.userActions.setUser(res.data.user));
        cookie.set("token", res.data.access_token);
        localStorage.setItem("user_type", 0);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        // this.props.setLogin(res.data.user);
        navigate("/admin/homepage");
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          toast("Unauthorized", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        } else {
          toast("Error", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          console.log(e);
        }
      });
  };

  const handleChangeInput = (event) => {
    const {id, value, checked, type} = event.target;
    if (type === "checkbox") {
      setForm({
        ...form,
        [id]: checked,
      });
    } else {
      setForm({
        ...form,
        [id]: value,
      });
    }
  };
  const currentUser = useSelector((state) => state.currentUser);
  useEffect(() => {
    if (currentUser.loggedIn) {
      navigate("/admin/homepage");
    }
  }, []);

  const enterClick = (e) => {
    if (e.key === "Enter") {
      handleFormSubmit(e);
    }
  };

  return (
    <div className="mt-5">
      <div className="container">
        <div className="row ">
          <div className=" text-center">
            <div>
              <img
                src={`${process.env.REACT_APP_FILES_PATH}/images/darbet-m3allem-logo.jpg`}
                alt="logo"
                style={{width: "15%"}}
              />
            </div>
            <div className=" text-center">
              <label htmlFor="staticEmail" className="col-sm-2 col-form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="example@example.com"
                style={{margin: "0 auto", width: "25rem"}}
                id="email"
                onChange={handleChangeInput}
                onKeyDown={enterClick}
                value={form.email}
              />
              <label htmlFor="staticEmail" className="col-sm-2 col-form-label mt-3">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                placeholder="password"
                style={{margin: "0 auto", width: "25rem"}}
                id="password"
                onChange={handleChangeInput}
                onKeyDown={enterClick}
                value={form.password}
              />
              <button
                className="btn btn-primary mt-5"
                style={{margin: "0 auto", width: "25rem"}}
                onClick={handleFormSubmit}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminLogin;
