import React, {useState, useEffect} from "react";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

import {CircularProgress} from "@material-ui/core";
import {CKEditor} from "ckeditor4-react";

const AdminTermsConditions = ({children, match, history}) => {
  const [isLoading, setIsLoading] = useState(false);

  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    text1: "",
    text2: "",
  });

  const saveForm = () => {
    setSaveIsLoading(true);

    const submitFormData = new FormData();
    submitFormData.append("text1", formData.text1);
    submitFormData.append("text2", formData.text2);

    if (formData.id > 0) {
      submitFormData.append("id", formData.id);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/terms-and-conditions/update`,
        data: submitFormData,
      })
        .then((response) => {
          setSaveIsLoading(false);
          toast.success("Success");
        })
        .catch((error) => console.log(error));
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/terms-and-conditions/add`,
        data: submitFormData,
      })
        .then((response) => {
          console.log(response);
          setSaveIsLoading(false);
          toast.success("Success");
        })
        .catch((error) => console.log(error));
    }
  };

  const getData = () => {
    setIsLoading(true);

    axios
      .get(`${process.env.REACT_APP_API}/admin/terms-and-conditions/get`)
      .then((response) => {
        let terms_conditions = response.data;
        setFormData({
          id: terms_conditions.id,
          text1: terms_conditions.text1,
          text2: terms_conditions.text2,
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e) => {
    const {id, value} = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="row">
          <label htmlFor="name">Worker Text </label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="text1"
            onChange={handleInputChange}
            value={formData.text1}
          />

          <label htmlFor="name">Customer Text </label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="text2"
            onChange={handleInputChange}
            value={formData.text2}
          />

          {/* <label htmlFor="name">French Text</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="french_text"
            onChange={handleInputChange}
            value={formData.french_text}
          /> */}
          {/* 
          <label htmlFor="description">Long English terms and consitions</label>
          <CKEditor
            style={{ width: "100%" }}
            id="description"
            initData={formData.description}
            onChange={(e) => {
              const data = e.editor.getData();
              setFormData((prev) => ({ ...prev, description: data }));
            }}
          /> */}

          {/* <label htmlFor="french_description">
            Long French terms and consitions
          </label>
          <CKEditor
            style={{ width: "100%" }}
            id="french_description"
            initData={formData.french_description}
            onChange={(e) => {
              const data = e.editor.getData();
              setFormData((prev) => ({ ...prev, french_description: data }));
            }}
          /> */}
        </div>

        <div className="row">
          <div className={"col-sm-12"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button style={{margin: 10}} className="btn btn-md btn-primary float-end" onClick={saveForm}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminTermsConditions;
