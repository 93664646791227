import React, {useState} from "react";
import axios from "axios";
import {Line} from "react-chartjs-2";
import styled from "styled-components";
import {TextField, Button, Container, Typography, Paper, Grid} from "@mui/material";
import AdminLayout from "../AdminLayout";

// Styled Components
const FormContainer = styled(Paper)`
  padding: 20px;
  margin-bottom: 20px;
`;

const DateInput = styled(TextField)`
  margin-right: 20px;
`;

const ResultContainer = styled(Paper)`
  padding: 20px;
  margin-top: 20px;
`;

const ChartContainer = styled(Paper)`
  padding: 20px;
  margin-top: 20px;
`;

const Report = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [report, setReport] = useState(null);

  const fetchReport = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/admin/jobs/report`, {
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      });
      setReport(response.data);
    } catch (error) {
      console.error("There was an error fetching the report!", error);
    }
  };

  const data = {
    labels: report?.data.map((payment) => payment.due_date),
    datasets: [
      {
        label: "In",
        data: report?.data.filter((payment) => payment.type === "in").map((payment) => payment.amount),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Out",
        data: report?.data.filter((payment) => payment.type === "out").map((payment) => payment.amount),
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    interaction: {
      mode: "nearest",
      axis: "x",
      intersect: false,
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "MMM DD",
        },
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Amount ($)",
        },
        beginAtZero: true,
      },
    },
  };
  return (
    <AdminLayout>
      <Container>
        <Typography variant="h4" gutterBottom>
          Job Payments Report
        </Typography>
        <FormContainer elevation={3}>
          <Typography variant="h6" gutterBottom>
            Select Date Range
          </Typography>
          <Grid container alignItems="center">
            <DateInput
              label="Start Date"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              InputLabelProps={{shrink: true}}
            />
            <DateInput
              label="End Date"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              InputLabelProps={{shrink: true}}
            />
            <Button variant="contained" color="primary" onClick={fetchReport}>
              Get Report
            </Button>
          </Grid>
        </FormContainer>
        {report && (
          <ResultContainer elevation={3}>
            <Typography variant="h6" gutterBottom>
              Report Summary
            </Typography>
            <Typography variant="body1">
              <strong>Total In:</strong> ${report.total_in}
            </Typography>
            <Typography variant="body1">
              <strong>Total Out:</strong> ${report.total_out}
            </Typography>
            <Typography variant="body1">
              <strong>Revenue:</strong> ${report.revenue}
            </Typography>
          </ResultContainer>
        )}
        {report && (
          <ChartContainer elevation={3}>
            <Line data={data} options={options} />
          </ChartContainer>
        )}
      </Container>
    </AdminLayout>
  );
};

export default Report;
