const seEnglishLanguage = () => {
  return {
    type: "SET_LANGUAGE_EN",
  };
};

const setFrenchLanguage = () => {
  return {
    type: "SET_LANGUAGE_FR",
  };
};

const languageObj = {
  seEnglishLanguage,
  setFrenchLanguage,
};
export default languageObj;
