import {AppBar, CircularProgress, Dialog, Slide, Toolbar} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import MUIDataTable from "mui-datatables";
import React, {useEffect, useState} from "react";
import {FileUploader} from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import {toast} from "react-toastify";
import AdminLayout from "./AdminLayout";
import Swal from "sweetalert2";
import axios from "axios";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
//Local requests
const AdminInspirations = () => {
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [file, setFile] = useState([]);
  const [viewedFile, setViewedFile] = useState([]);
  const [files, setFiles] = useState([]);
  const [viewedFiles, setViewedFiles] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    frenchTitle: "",
    description: "",
    frenchDescription: "",
    author: "",

    id: -1,
  });

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "title",
      label: "Title",
    },
    {
      name: "frenchTitle",
      label: "French Title",
    },
    {
      name: "description",
      label: "Description",
    },
    // {
    //   name: "frenchDescription",
    //   label: "French Description",
    // },
    {
      name: "author",
      label: "Author",
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteForm(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/inspirations/getAll`)
      .then(function (response) {
        setData(response.data);

        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addForm = () => {
    setVisible(!visible);
    resetForm();
  };

  const handleInputChange = (e) => {
    const {id, value, title, frenchTitle, description, frenchDescription, author} = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleChangeFile = (imgFile) => {
    const fileData = imgFile;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      let temp2 = [];
      file.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFile(temp2);
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFile.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFile(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleChangeFiles = async (originalFile) => {
    const file = await imageCompression(originalFile, options);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      let temp2 = [];
      files.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFiles(temp2);
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const removeImages = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/inspirations/deleteFromAllImages`,
            data: {id: id},
          })
            .then((response) => {
              let arr = files;
              let arr2 = viewedFiles;
              arr = arr.filter((v) => {
                return v.id != id;
              });
              arr2 = arr2.filter((v) => {
                return v.id != id;
              });
              setFiles(arr);
              setViewedFiles(arr2);
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = files;
      let arr2 = viewedFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFiles(arr);
      setViewedFiles(arr2);
    }
  };

  const removeImage = (id) => {
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/inspirations/deleteMainImage`,
            data: {id: id},
          })
            .then((response) => {
              let arr = file;
              let arr2 = viewedFile;
              arr = arr.filter((v) => {
                return v.id != id;
              });
              arr2 = arr2.filter((v) => {
                return v.id != id;
              });
              setFile(arr);
              setViewedFile(arr2);
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = file;
      let arr2 = viewedFile;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFile(arr);
      setViewedFile(arr2);
    }
  };

  const resetForm = () => {
    setFormData({
      title: "",
      frenchTitle: "",
      description: "",
      frenchDescription: "",
      author: "",
      id: -1,
    });

    setViewedFile([]);
    setFile([]);

    setViewedFiles([]);
    setFiles([]);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const updateFrom = (meta) => {
    resetForm();
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/inspirations/getRecord/${id}`,
    })
      .then((response) => {
        setFormData({
          title: response.data.title,
          frenchTitle: response.data.frenchTitle,
          description: response.data.description,
          frenchDescription: response.data.frenchDescription,
          author: response.data.author,

          id: id,
        });

        let temp1 = [];
        response.data.mainImages.forEach((element) => {
          temp1.push({
            id: element.id,
            path: `${process.env.REACT_APP_FILES_PATH}/adminInspirations/${element.image_name}`,
          });
        });
        setViewedFile(temp1);

        let temp2 = [];
        response.data.allImages.forEach((element) => {
          temp2.push({
            id: element.id,
            path: `${process.env.REACT_APP_FILES_PATH}/adminInspirations/${element.image_name}`,
          });
        });
        setViewedFiles(temp2);

        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/inspirations/deleteRecord`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    if (formData.id > 0) {
      const submitFormData = new FormData();
      submitFormData.append("title", formData.title);
      submitFormData.append("frenchTitle", formData.frenchTitle);
      submitFormData.append("description", formData.description);
      submitFormData.append("frenchDescription", formData.frenchDescription);
      submitFormData.append("author", formData.author);

      submitFormData.append("id", formData.id);
      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });
      files?.map((fileData) => {
        submitFormData.append("files[]", fileData.file);
      });
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/inspirations/updateRecord`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      const submitFormData = new FormData();
      submitFormData.append("title", formData.title);
      submitFormData.append("frenchTitle", formData.frenchTitle);
      submitFormData.append("description", formData.description);
      submitFormData.append("frenchDescription", formData.frenchDescription);
      submitFormData.append("author", formData.author);

      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });

      files?.map((fileData) => {
        submitFormData.append("files[]", fileData.file);
      });

      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/inspirations/add`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Inspirations"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>
          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                {!viewedFile.length > 0 && (
                  <FileUploader
                    multiple={false}
                    classes="w-100"
                    handleChange={handleChangeFile}
                    name="file"
                    types={fileTypes}
                  >
                    <div
                      className="w-100"
                      style={{
                        minHeight: "25vh",
                        border: "2px dashed #ced4da",
                        padding: "10px",
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <p>DROP YOUR MAIN IMAGE HERE</p>
                    </div>
                  </FileUploader>
                )}
                {viewedFile?.map((imageData, index) => {
                  return (
                    <div className="col-sm-2" key={index}>
                      <img src={imageData.path} className="w-100 mt-3" />
                      <button
                        className="btn btn-danger"
                        style={{position: "absolute"}}
                        onClick={() => {
                          removeImage(imageData.id);
                        }}
                      >
                        X
                      </button>
                    </div>
                  );
                })}
                <div className="form-group">
                  <label htmlFor="name">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    onChange={handleInputChange}
                    value={formData.title}
                  />

                  <label htmlFor="name">French Title</label>
                  <input
                    type="text"
                    className="form-control"
                    id="frenchTitle"
                    onChange={handleInputChange}
                    value={formData.frenchTitle}
                  />

                  <label htmlFor="name">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    onChange={handleInputChange}
                    value={formData.description}
                  />

                  {/* <label htmlFor="name">French Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="frenchDescription"
                    onChange={handleInputChange}
                    value={formData.frenchDescription}
                  /> */}

                  <label htmlFor="name">Author</label>
                  <input
                    type="text"
                    className="form-control"
                    id="author"
                    onChange={handleInputChange}
                    value={formData.author}
                  />
                </div>
                <FileUploader classes="w-100" handleChange={handleChangeFiles} name="file" types={fileTypes}>
                  <div
                    className="w-100"
                    style={{
                      minHeight: "25vh",
                      border: "2px dashed #ced4da",
                      padding: "10px",
                      textAlign: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <p>DROP YOUR IMAGES HERE</p>
                  </div>
                </FileUploader>
                <div className="row mt-3">
                  {viewedFiles?.map((imageData, index) => {
                    return (
                      <div className="col-sm-2" key={index}>
                        <img src={imageData.path} className="w-100 mt-3" />
                        <button
                          className="btn btn-danger"
                          style={{position: "absolute"}}
                          onClick={() => {
                            removeImages(imageData.id);
                          }}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};

export default AdminInspirations;
