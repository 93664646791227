import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import TextField from "@mui/material/TextField";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@mui/material/Autocomplete";
import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import {FileUploader} from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import {generateForm} from "../../helpers/functions";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminCustomers = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState("");
  const [selectedcountry, setselectedCountry] = useState("");
  const [governorates, setGovernorates] = useState("");
  const [selectedgovernorate, setselectedGovernorate] = useState("");
  const [regions, setRegions] = useState("");
  const [selectedregion, setselectedRegion] = useState("");

  const [viewedFile, setViewedFile] = useState([]);
  const [file, setFile] = useState([]);

  const [viewedIdFile, setViewedIdFile] = useState([]);
  const [idFile, setIdFile] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    profile_description: "",
    country_id: "",
    governorate_id: "",
    region_id: "",
    address: "",

    subscription_type_id: "",
    user_type: 2,
    id: -1,
  });
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/getCustomers`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addForm = () => {
    setVisible(!visible);
    resetForm();
  };

  const updateFrom = (meta) => {
    resetForm();
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/users/getById/${id}`,
    })
      .then((response) => {
        setFormData({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email,
          password: "",
          profile_description: response.data.profile_description,
          phone: response.data.phone,
          country_id: response.data.country_id,
          governorate_id: response.data.governorate_id,
          region_id: response.data.region_id,
          address: response.data.address,

          subscription_type_id: response.data.subscription_type_id,

          id: id,

          user_type: 2,
        });

        setselectedCountry(countries.find((country) => country.id == response.data.country_id));
        setselectedGovernorate(
          governorates.find((governorate) => governorate.id == response.data.governorate_id)
        );
        setselectedRegion(regions.find((region) => region.id == response.data.region_id));

        let temp1 = [];
        if (response.data.profile_image_name)
          temp1.push({
            id: response.data.id,
            path: `${process.env.REACT_APP_FILES_PATH}/userProfiles/${response.data.profile_image_name}`,
          });

        setViewedFile(temp1);

        let temp2 = [];
        if (response.data.id_image_name)
          temp2.push({
            id: response.data.id,
            path: `${process.env.REACT_APP_FILES_PATH}/userProfiles/${response.data.id_image_name}`,
          });

        setViewedIdFile(temp2);

        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    if (formData.id > 0) {
      const submitFormData = generateForm(formData);
      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/users/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      const submitFormData = generateForm(formData);
      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/users/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const deactivateForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/users/deactivate`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const activateForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Ativate",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/users/activate`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };
  const deleteUser = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/users/delete`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };
  const removeImage = (id, type) => {
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/users/deleteProfileImage`,
            data: {id: id, type: type},
          })
            .then((response) => {
              if (type == "profile") {
                let arr = file;
                let arr2 = viewedFile;
                arr = arr.filter((v) => {
                  return v.id != id;
                });
                arr2 = arr2.filter((v) => {
                  return v.id != id;
                });
                setFile(arr);
                setViewedFile(arr2);
              }

              if (type == "id") {
                let arr = idFile;
                let arr2 = viewedIdFile;
                arr = arr.filter((v) => {
                  return v.id != id;
                });
                arr2 = arr2.filter((v) => {
                  return v.id != id;
                });
                setIdFile(arr);
                setViewedIdFile(arr2);
              }
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      if (type == "profile") {
        let arr = file;
        let arr2 = viewedFile;
        arr = arr.filter((v) => {
          return v.id != id;
        });
        arr2 = arr2.filter((v) => {
          return v.id != id;
        });
        setFile(arr);
        setViewedFile(arr2);
      }

      if (type == "id") {
        let arr = idFile;
        let arr2 = viewedIdFile;
        arr = arr.filter((v) => {
          return v.id != id;
        });
        arr2 = arr2.filter((v) => {
          return v.id != id;
        });
        setIdFile(arr);
        setViewedIdFile(arr2);
      }
    }
  };
  const handleInputChange = (e) => {
    const {
      id,

      value,
      checked,
      type,
    } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const changeAutoCompleteValue = (value, type) => {
    const newForm = formData;
    newForm[type] = value.toString();
    setFormData(newForm);
  };

  const handleChangeFile = async (originalFile, type) => {
    const imgFile = await imageCompression(originalFile, options);
    const fileData = imgFile;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      let temp2 = [];
      file.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      if (type == "profile") {
        setFile(temp2);
      }
      if (type == "id") {
        setIdFile(temp2);
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFile.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        if (type == "profile") {
          setViewedFile(temp);
        }
        if (type == "id") {
          setViewedIdFile(temp);
        }
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      profile_description: "",
      password: "",
      country_id: "",
      governorate_id: "",
      region_id: "",
      address: "",

      subscription_type_id: "",

      id: -1,

      user_type: 2,
    });

    setViewedFile([]);
    setFile([]);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "first_name",
      label: "First Name",
    },
    {
      name: "last_name",
      label: "Last Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phone",
      label: "Phone",
    },

    {
      name: "active",
      label: "Active",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value == 1 ? true : false}
            />
          );
        },
      },
    },
    {
      name: "ACTIVATION",
      label: "ACTIVATION",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              {meta.rowData[5] == 0 ? (
                <button
                  className="btn btn-success mx-1"
                  onClick={() => {
                    activateForm(meta);
                  }}
                >
                  Activate
                </button>
              ) : (
                <button
                  className="btn btn-danger mx-1"
                  onClick={() => {
                    deactivateForm(meta);
                  }}
                >
                  Deactivate
                </button>
              )}
            </div>
          );
        },
      },
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteUser(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/noAuthApi/getSignUpData`, {})
      .then(function (response) {
        const data = response.data;

        setCountries(data.Countries);
        setGovernorates(data.Governorates);
        setRegions(data.Regions);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Customers"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>

          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  {!viewedFile.length > 0 && (
                    <FileUploader
                      multiple={false}
                      classes="w-100"
                      handleChange={(e) => handleChangeFile(e, "profile")}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        className="w-100"
                        style={{
                          minHeight: "25vh",
                          border: "2px dashed #ced4da",
                          padding: "10px",
                          textAlign: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <p>DROP YOUR ICON HERE</p>
                      </div>
                    </FileUploader>
                  )}
                  {viewedFile?.map((imageData, index) => {
                    return (
                      <div className="col-sm-2" key={index}>
                        <img src={imageData.path} className="w-100 mt-3" />
                        <button
                          className="btn btn-danger"
                          style={{position: "absolute"}}
                          onClick={() => {
                            removeImage(imageData.id, "profile");
                          }}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                  <label htmlFor="first_name">First Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    onChange={handleInputChange}
                    value={formData.first_name}
                  />

                  <label htmlFor="last_name">Last Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    onChange={handleInputChange}
                    value={formData.last_name}
                  />

                  <label htmlFor="email: ">Email</label>
                  <input
                    type="text"
                    className="form-control"
                    id="email"
                    onChange={handleInputChange}
                    value={formData.email}
                  />

                  <label htmlFor="password">password</label>
                  <input
                    type="text"
                    className="form-control"
                    id="password"
                    onChange={handleInputChange}
                    value={formData.password}
                  />

                  <label htmlFor="phone">Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone"
                    onChange={handleInputChange}
                    value={formData.phone}
                  />

                  <label htmlFor="profile_description">Profile Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="profile_description"
                    onChange={handleInputChange}
                    value={formData.profile_description}
                  />

                  <label htmlFor="address">Address </label>
                  <input
                    type="text"
                    className="form-control"
                    id="address"
                    onChange={handleInputChange}
                    value={formData.address}
                  />

                  {
                    // countries && governorates &&
                    regions && (
                      <>
                        {/* <Autocomplete
                        style={{marginTop: 20}}
                        disablePortal
                        id="country_id"
                        options={countries}
                        getOptionLabel={(country) => country.name}
                        value={selectedcountry}
                        onChange={(event, value) => {
                          setselectedCountry(value);
                          changeAutoCompleteValue(value.id, "country_id");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Countries" required id="Countries" />
                        )}
                      /> */}
                        {/* <Autocomplete
                        style={{marginTop: 20}}
                        disablePortal
                        id="governorate_id"
                        options={governorates}
                        getOptionLabel={(governorate) => governorate.name}
                        value={selectedgovernorate}
                        onChange={(event, value) => {
                          setselectedGovernorate(value);
                          changeAutoCompleteValue(value.id, "governorate_id");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Governorates" required id="Governorates" />
                        )}
                      /> */}
                        {/* <Autocomplete
                          style={{marginTop: 20}}
                          disablePortal
                          id="region_id"
                          options={regions}
                          getOptionLabel={(region) => region.name}
                          value={selectedregion}
                          onChange={(event, value) => {
                            setselectedRegion(value);
                            changeAutoCompleteValue(value.id, "region_id");
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Regions" required id="region_id" />
                          )}
                        /> */}
                      </>
                    )
                  }
                </div>

                {!viewedIdFile && (
                  <FileUploader
                    multiple={false}
                    classes="w-100"
                    handleChange={(e) => handleChangeFile(e, "id")}
                    name="file"
                    types={fileTypes}
                  >
                    <div
                      className="w-100"
                      style={{
                        minHeight: "25vh",
                        border: "2px dashed #ced4da",
                        padding: "10px",
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <p>DROP YOUR ICON HERE</p>
                    </div>
                  </FileUploader>
                )}

                {viewedIdFile?.map((imageData, index) => {
                  return (
                    <div className="col-sm-2" key={index}>
                      <img src={imageData.path} className="w-100 mt-3" />
                      <button
                        className="btn btn-danger"
                        style={{position: "absolute"}}
                        onClick={() => {
                          removeImage(imageData.id, "id");
                        }}
                      >
                        X
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default AdminCustomers;
