import React, {useState, useEffect} from "react";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {FileUploader} from "react-drag-drop-files";
import {CircularProgress} from "@material-ui/core";
import imageCompression from "browser-image-compression";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const AdminHomePage = ({children, match, history}) => {
  const [adminHomeImagesData, setAdminHomeImagesData] = useState([]);
  const [adminMobileHomeImagesData, setAdminMobileHomeImagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [viewedFiles, setViewedFiles] = useState([]);
  const [mobileFiles, setMobileFiles] = useState([]);
  const [mobileViewedFiles, setMobileViewedFiles] = useState([]);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    frenchSubtitle: "",
    id: -1,
  });

  const saveForm = () => {
    setSaveIsLoading(true);
    const submitFormData = new FormData();
    files?.map((fileData) => {
      submitFormData.append("files[]", fileData.file);
    });

    axios({
      method: "post",
      accept: "any",
      url: `${process.env.REACT_APP_API}/admin/homeImages/add`,
      data: submitFormData,
    })
      .then((response) => {
        //setCurrentId(response.data.serial)
        toast.success(`Inserted successfully`);
        setSaveIsLoading(false);
        getData();
        setFiles([]);
        setViewedFiles([]);
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error(`Error While Saving`);
      });

    const submitMobileFormData = new FormData();
    mobileFiles?.map((fileData) => {
      submitMobileFormData.append("files[]", fileData.file);
    });

    axios({
      method: "post",
      accept: "any",
      url: `${process.env.REACT_APP_API}/admin/mobileHomeImages/add`,
      data: submitMobileFormData,
    })
      .then((response) => {
        //setCurrentId(response.data.serial)
        toast.success(`Inserted successfully`);
        setSaveIsLoading(false);
        getData();
        setMobileFiles([]);
        setMobileViewedFiles([]);
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error(`Error While Saving`);
      });

    if (formData.id > 0) {
      const submitFormData = new FormData();
      submitFormData.append("title", formData.title);
      submitFormData.append("subtitle", formData.subtitle);
      submitFormData.append("frenchSubtitle", formData.frenchSubtitle);
      submitFormData.append("id", formData.id);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/header/update`,
        data: submitFormData,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    } else {
      const submitFormData = new FormData();
      submitFormData.append("title", formData.title);
      submitFormData.append("subtitle", formData.subtitle);
      submitFormData.append("frenchSubtitle", formData.frenchSubtitle);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/header/add`,
        data: submitFormData,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    }
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/homeImages/get`, {})
      .then(function (response) {
        setIsLoading(false);
        setAdminHomeImagesData(response.data);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });

    axios
      .get(`${process.env.REACT_APP_API}/admin/mobileHomeImages/get`, {})
      .then(function (response) {
        setIsLoading(false);
        setAdminMobileHomeImagesData(response.data);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });

    axios
      .get(`${process.env.REACT_APP_API}/admin/header/get`)
      .then((response) => {
        let header = response.data[0];
        setFormData({
          title: header.title,
          subtitle: header.subtitle,
          frenchSubtitle: header.frenchSubtitle,
          id: header.id,
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getData();
  }, []);

  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/homeImages/delete`,
            data: {id: id},
          })
            .then((response) => {
              getData();
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = files;
      let arr2 = viewedFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFiles(arr);
      setViewedFiles(arr2);
    }
  };

  const removeMobileImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/mobileHomeImages/delete`,
            data: {id: id},
          })
            .then((response) => {
              getData();
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = mobileFiles;
      let arr2 = mobileViewedFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setMobileFiles(arr);
      setMobileViewedFiles(arr2);
    }
  };

  const handleChangeFile = async (originalFile) => {
    const file = await imageCompression(originalFile, options);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
      let temp2 = [];
      files.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFiles(temp2);

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleMobileChangeFile = async (originalFile) => {
    const file = await imageCompression(originalFile, options);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
      let temp2 = [];
      mobileFiles.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setMobileFiles(temp2);

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        mobileViewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setMobileViewedFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const {id, value, title, subtitle, frenchSubtitle} = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="row ">
          <div className={"col-sm-12"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                Save
              </button>
            )}
          </div>

          <label htmlFor="name">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            onChange={handleInputChange}
            value={formData.title}
          />
          <label htmlFor="name">Subtitle</label>
          <input
            type="text"
            className="form-control"
            id="subtitle"
            onChange={handleInputChange}
            value={formData.subtitle}
          />

          {/* <label htmlFor="name">French Subtitle</label>
          <input
            type="text"
            className="form-control"
            id="frenchSubtitle"
            onChange={handleInputChange}
            value={formData.frenchSubtitle}
          /> */}

          <FileUploader classes="w-100" handleChange={handleChangeFile} name="file" types={fileTypes}>
            <div
              className="w-100"
              style={{
                minHeight: "25vh",
                border: "2px dashed #ced4da",
                padding: "10px",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              <p>DROP YOUR IMAGES HERE</p>
            </div>
          </FileUploader>
          <div className="row mt-3">
            {viewedFiles?.map((imageData, index) => {
              return (
                <div className="col-sm-2" key={index}>
                  <img src={imageData.path} className="w-100 mt-3" />
                  <button
                    className="btn btn-danger"
                    style={{position: "absolute"}}
                    onClick={() => {
                      removeImage(imageData.id);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
          </div>

          <hr className="mt-3" />
          <h3 className="mt-3">Published For Desktop</h3>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div className="row mt-3">
              {adminHomeImagesData?.map((imageData, index) => {
                return (
                  <div className="col-sm-2" key={index}>
                    <img
                      src={`${process.env.REACT_APP_FILES_PATH}/adminHome/${imageData.image_name}`}
                      className="w-100 mt-3"
                    />
                    <button
                      className="btn btn-danger"
                      style={{position: "absolute"}}
                      onClick={() => {
                        removeImage(imageData.id);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
          )}

          <FileUploader classes="w-100" handleChange={handleMobileChangeFile} name="file" types={fileTypes}>
            <div
              className="w-100"
              style={{
                minHeight: "25vh",
                border: "2px dashed #ced4da",
                padding: "10px",
                textAlign: "center",
                marginTop: "1rem",
              }}
            >
              <p>DROP YOUR MOBILE IMAGES HERE</p>
            </div>
          </FileUploader>
          <div className="row mt-3">
            {mobileViewedFiles?.map((imageData, index) => {
              return (
                <div className="col-sm-2" key={index}>
                  <img src={imageData.path} className="w-100 mt-3" />
                  <button
                    className="btn btn-danger"
                    style={{position: "absolute"}}
                    onClick={() => {
                      removeMobileImage(imageData.id);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
          </div>

          <hr className="mt-3" />
          <h3 className="mt-3">Published For Mobile</h3>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <div className="row mt-3">
              {adminMobileHomeImagesData?.map((imageData, index) => {
                return (
                  <div className="col-sm-2" key={index}>
                    <img
                      src={`${process.env.REACT_APP_FILES_PATH}/adminHome/${imageData.image_name}`}
                      className="w-100 mt-3"
                    />
                    <button
                      className="btn btn-danger"
                      style={{position: "absolute"}}
                      onClick={() => {
                        removeMobileImage(imageData.id);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          <hr className="mt-3" />
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminHomePage;
