import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import {FileUploader} from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import {generateForm} from "../../helpers/functions";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminAds = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [venders, setVenders] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [viewedFile, setViewedFile] = useState([]);
  const [file, setFile] = useState([]);

  const [formData, setFormData] = useState({
    link: "",
    user_id: "",
    targeted: false,
    id: -1,
  });
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/mobile/otherApis/getAds`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  const getVenders = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/getVenders`, {})
      .then(function (response) {
        setVenders(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    getVenders();
  }, []);

  const addForm = () => {
    setVisible(!visible);

    resetForm();
  };

  const updateFrom = (meta) => {
    resetForm();
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/mobile/otherApis/getById/${id}`,
    })
      .then((response) => {
        setFormData({
          link: response.data.link,
          user_id: response.data.user_id,
          targeted: response.data.targeted == 1 ? true : false,
          id: id,
        });
        if (response.data.image_name) {
          let temp = {
            id: id,
            path: `${process.env.REACT_APP_FILES_PATH}/adsImages/${response.data.image_name}`,
          };

          setViewedFile(temp);
        }

        setSelectedVendor(venders.find((vendor) => vendor.id == response.data.user_id));

        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    const submitFormData = generateForm(formData);

    submitFormData.append("file[]", file.file);
    if (formData.id > 0) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/mobile/otherApis/updateAd`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/mobile/otherApis/addAd`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/mobile/otherApis/deleteAd`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };
  const removeImage = (id) => {
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/mobile/otherApis/deleteAdImage`,
            data: {id: id},
          })
            .then((response) => {
              setFile("");
              setViewedFile("");
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      setFile("");
      setViewedFile("");
    }
  };
  const handleInputChange = (e) => {
    const {id, value, checked, type} = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleChangeFile = async (originalFile) => {
    const imgFile = await imageCompression(originalFile, options);
    const fileData = imgFile;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      setFile({id: 0, file: fileData});
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        setViewedFile({id: 0, path: fileData, path: imagePath});
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      link: "",
      user_id: "",
      targeted: false,
      id: -1,
    });

    setViewedFile("");
    setFile("");
  };

  const handleClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "link",
      label: "Link",
    },
    {
      name: "user_name",
      label: "User Name",
    },

    {
      name: "targeted",
      label: "Targeted",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value === 1 ? true : false}
            />
          );
        },
      },
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteForm(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Categories"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>

          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  {!viewedFile && (
                    <FileUploader
                      multiple={false}
                      classes="w-100"
                      handleChange={handleChangeFile}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        className="w-100"
                        style={{
                          minHeight: "25vh",
                          border: "2px dashed #ced4da",
                          padding: "10px",
                          textAlign: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <p>DROP YOUR ICON HERE</p>
                      </div>
                    </FileUploader>
                  )}

                  {viewedFile && (
                    <div className="col-sm-2">
                      <img src={viewedFile.path} className="w-100 mt-3" />
                      <button
                        className="btn btn-danger"
                        style={{position: "absolute"}}
                        onClick={() => {
                          removeImage(viewedFile.id);
                        }}
                      >
                        X
                      </button>
                    </div>
                  )}

                  <label htmlFor="link">Link</label>
                  <input
                    type="text"
                    className="form-control"
                    id="link"
                    onChange={handleInputChange}
                    value={formData.link}
                  />

                  {venders && (
                    <Autocomplete
                      style={{marginTop: 20}}
                      disablePortal
                      id="user_id"
                      options={venders}
                      getOptionLabel={(vendor) => (vendor.name ? vendor.name : "")}
                      value={selectedVendor}
                      onChange={(event, value) => {
                        setSelectedVendor(value);
                        setFormData({
                          ...formData,
                          user_id: value.id,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Venders" required id="Venders" />
                      )}
                    />
                  )}
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div class="form-check mt-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="targeted"
                    checked={formData.targeted}
                    onChange={handleInputChange}
                    value={formData.targeted || ""}
                  />
                  <label class="form-check-label" for="targeted">
                    Targeted
                  </label>
                </div>
              </div>

              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default AdminAds;
