import React, { useState, useEffect } from "react";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import { CircularProgress } from "@material-ui/core";
import imageCompression from "browser-image-compression";

const AdminHomePage = ({ children, match, history }) => {
  const [adminHomeImagesData, setAdminHomeImagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [secondFiles, setSecondFiles] = useState([]);
  const [viewedFiles, setViewedFiles] = useState([]);
  const [viewedSecondFiles, setViewedSecondFiles] = useState([]);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: -1,
    description: "",
    french_description: "",
  });

  const saveForm = () => {
    setSaveIsLoading(true);
    const submitFormData = new FormData();

    submitFormData.append("description", formData.description);
    submitFormData.append("french_description", formData.french_description);

    if (formData.id > 0) {
      submitFormData.append("id", formData.id);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/aboutus/updateSectionFive`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Success`);
          console.log(response);
          setSaveIsLoading(false);
        })
        .catch((error) => console.log(error));
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/aboutus/addSectionFive`,
        data: submitFormData,
      })
        .then((response) => {
          setSaveIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error(`Error`);
          setSaveIsLoading(false);
        });
    }
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/aboutus/getSectionFive`)
      .then((response) => {
        let sectionFive = response.data[0];
        setIsLoading(false);
        if (!sectionFive) {
          return;
        }
        setFormData({
          id: sectionFive.id,
          description: sectionFive.description,
          french_description: sectionFive.french_description,
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(`Error`);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="row admin-aboutus-row" style={{ marginBottom: 100 }}>
          <div className={"col-sm-12"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button
                className="btn btn-md btn-primary float-end"
                onClick={saveForm}
              >
                Save
              </button>
            )}
          </div>

          <label htmlFor="name">Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="description"
            onChange={handleInputChange}
            value={formData.description}
          />

          <label htmlFor="name">French Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="french_description"
            onChange={handleInputChange}
            value={formData.french_description}
          />
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminHomePage;
