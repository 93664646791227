import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
// import '@fortawesome/fontawesome-free/css/all.min.css'
// import 'bootstrap-css-only/css/bootstrap.min.css'
// import 'mdbreact/dist/css/mdb.css'

import Routes from "./Routes";
import reportWebVitals from "./reportWebVitals";
import {Provider} from "react-redux";
import store from "./store";
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
//talkeez testing
// const initialOptions = {
//   "client-id":
//     "Aak3p1kIcskUXIdw5Lb3h-mxZijMh7wjrVouooJIh795waQd0dCiaQPJMLM8FyzwpcaGa7vt9NCiZlBA",
//   currency: "USD",
//   intent: "capture",
//   "data-client-token": "abc123xyz==",
// };
ReactDOM.render(
  <Provider store={store}>
    {/* <PayPalScriptProvider
      options={{
        "client-id":
          // "Aak3p1kIcskUXIdw5Lb3h-mxZijMh7wjrVouooJIh795waQd0dCiaQPJMLM8FyzwpcaGa7vt9NCiZlBA",
          // "AccUGlCYG89iMvpxOR-r8E607T7w0Vv6fHHurZB-nWOsLGjyVeokPl2kY-L1IpwU4rX87n1J5QfjZdma",
          process.env.REACT_APP_CLIENT_ID,
      }}
    > */}
    <Routes />
    {/* </PayPalScriptProvider> */}
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
