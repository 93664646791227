import React from "react";

import PropTypes from "prop-types";
import {Box, Modal} from "@mui/material";
const style = {
  margin: "auto",
  width: "70%",
  height: "50%",
  // border: "2px solid #000",

  backgroundColor: "white",

  padding: "20px",
};
const CustomDialog = (props) => {
  return (
    <Modal open={props.open} onClose={props.onClose} style={{zIndex: 10000}}>
      <Box sx={style}>
        {props.hideHeader ? null : (
          <div style={{marginBottom: 50}}>
            <h1>{props.title ? props.title : "Filter"}</h1>
          </div>
        )}
        <div style={{marginBottom: 50}}>{props.children}</div>
        <div>
          {props.handleclear && (
            <button className="btn btn-md btn-primary" onClick={props.handleclear} color="light">
              Clear Filter
            </button>
          )}
          <button className="btn btn-md btn-primary float-end" onClick={props.onSave}>
            Save
          </button>
        </div>
      </Box>
    </Modal>
  );
};

CustomDialog.propTypes = {
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  open: PropTypes.any,
  title: PropTypes.any,
  handleclear: PropTypes.func,
  children: PropTypes.any,
  size: PropTypes.any,
  hideHeader: PropTypes.any,
};

export default CustomDialog;
