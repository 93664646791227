import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import PropTypes from "prop-types"


const CustomAutocomplete = props => {
  const filedName = props.filedName;
  const fieldLabel = props.label;
  const list = props.list;
  const fieldValues = props.values;
  const listKey = props.listKey;
  const listDescription = props.description;
  const customOnChange = props.customOnChange;

  return (
    <FormControl style={{ width: "100%" }}>
      <Autocomplete
        disabled={props.disabled ? props.disabled : false}
        // disableCloseOnSelect={true}
        name={filedName}
        options={list} // Options List
        value={fieldValues}
        getOptionSelected={(option, value) => (value == option[listKey])}
        multiple
        onChange={(event, newValue) => {
          const newList = newValue ? newValue.map((v) => v[listKey] ? v[listKey] : v) : [];
          customOnChange(newList, filedName, event);
        }}

        getOptionLabel={(option) => option[listDescription]}
        renderInput={(params) => <TextField {...params} label={fieldLabel} />}
        renderTags={(tagValue) => {
          const fillteredList = list.filter((v) => (tagValue.includes(v[listKey]) || tagValue.includes(`${v[listKey]}`)))
          return (<div>
            {fillteredList.map((value) => (
              <Chip
                key={value[listKey]}
                label={value[listDescription]}
                style={{ marginRight: "5px" }}
              />
            ))}
          </div>)
        }}
      />
      {fieldValues.map(v => <input key={v} type="hidden" name={`${filedName}[]`} value={v} />)}
    </FormControl>
  );
}
CustomAutocomplete.propTypes = {
  filedName: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  list: PropTypes.any.isRequired,
  values: PropTypes.any.isRequired,
  listKey: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  customOnChange: PropTypes.any.isRequired,
  disabled: PropTypes.any,
}

export default CustomAutocomplete;