import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {CKEditor} from "ckeditor4-react";
import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import {generateForm} from "../../helpers/functions";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AdminSubscriptionTypes = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    short_description: "",
    long_description: "",
    french_short_description: "",
    french_long_description: "",
    fees: "",
    no_of_bids: "",
    no_of_commercial_bids: "",
    expiry_days: "",
    is_active: true,
    contact_customer: true,
    id: -1,
  });
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [specs, setSpecs] = useState("");
  const [frenchSpecs, setFrenchSpecs] = useState("");
  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/subscriptionTypes/get`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addForm = () => {
    setVisible(!visible);
    resetForm();
  };

  const updateFrom = (meta) => {
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/subscriptionTypes/getById/${id}`,
    })
      .then((response) => {
        setFormData({
          short_description: response.data.short_description,
          long_description: response.data.long_description,
          french_short_description: response.data.french_short_description,
          french_long_description: response.data.french_long_description,
          fees: response.data.fees,
          no_of_bids: response.data.no_of_bids,
          no_of_commercial_bids: response.data.no_of_commercial_bids,
          expiry_days: response.data.expiry_days,
          is_active: response.data.is_active == 1 ? true : false,
          contact_customer: response.data.contact_customer == 1 ? true : false,
          id: id,
        });
        setSpecs(response.data.specs);
        setFrenchSpecs(response.data.french_specs);
        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    if (formData.id > 0) {
      const submitFormData = generateForm(formData);
      submitFormData.append("specs", specs);
      submitFormData.append("french_specs", frenchSpecs);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/subscriptionTypes/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      const submitFormData = generateForm(formData);
      submitFormData.append("specs", specs);
      submitFormData.append("french_specs", frenchSpecs);
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/subscriptionTypes/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/subscriptionTypes/delete`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const handleInputChange = (e) => {
    const {id, name, value, checked, type} = e.target;

    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      short_description: "",
      long_description: "",
      french_short_description: "",
      french_long_description: "",
      fees: "",
      no_of_bids: "",
      no_of_commercial_bids: "",
      expiry_days: "",
      is_active: true,
      contact_customer: true,
      id: -1,
    });
    setSpecs("");
    setFrenchSpecs("");
  };

  const handleClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "short_description",
      label: "Short Description",
    },
    // {
    //   name: "french_short_description",
    //   label: "French Short Description",
    // },
    {
      name: "long_description",
      label: "Long Description",
    },
    {
      name: "french_long_description",
      label: "French Long Description",
    },
    {
      name: "fees",
      label: "Fees",
    },
    {
      name: "is_active",
      label: "Active",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value === 1 ? true : false}
            />
          );
        },
      },
    },
    {
      name: "contact_customer",
      label: "Can Contact Customer",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value == 1 ? true : false}
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteForm(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Subscription Types"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>
          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="short_description">Short Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="short_description"
                    onChange={handleInputChange}
                    value={formData.short_description}
                  />

                  {/* <label htmlFor="french_short_description">
                    French Short Description
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="french_short_description"
                    onChange={handleInputChange}
                    value={formData.french_short_description}
                  /> */}
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="long_description">Long Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="long_description"
                    onChange={handleInputChange}
                    value={formData.long_description}
                  />

                  <label htmlFor="french_long_description">French Long Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="french_long_description"
                    onChange={handleInputChange}
                    value={formData.french_long_description}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="fees">Fees</label>
                  <input
                    type="number"
                    className="form-control"
                    id="fees"
                    onChange={handleInputChange}
                    value={formData.fees}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="expiry_days">Expiry Days</label>
                  <input
                    type="number"
                    className="form-control"
                    id="expiry_days"
                    onChange={handleInputChange}
                    value={formData.expiry_days}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="no_of_bids"># of Bids</label>
                  <input
                    type="number"
                    className="form-control"
                    id="no_of_bids"
                    onChange={handleInputChange}
                    value={formData.no_of_bids}
                  />
                </div>
              </div>

              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="no_of_commercial_bids"># of Commercial Bids</label>
                  <input
                    type="number"
                    className="form-control"
                    id="no_of_commercial_bids"
                    onChange={handleInputChange}
                    value={formData.no_of_commercial_bids}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-check mt-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="is_active"
                    onChange={handleInputChange}
                    checked={formData.is_active}
                  />
                  <label className="form-check-label" htmlFor="is_active">
                    Active
                  </label>
                </div>
              </div>

              <div className={"col-sm-6"}>
                <div className="form-check mt-5">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="contact_customer"
                    onChange={handleInputChange}
                    checked={formData.contact_customer}
                  />
                  <label className="form-check-label" htmlFor="contact_customer">
                    Can Contact Customer
                  </label>
                </div>
              </div>

              <div className={"col-sm-12"}>
                <label htmlFor="specs">Specs</label>
                <CKEditor
                  initData={specs}
                  onChange={(e) => {
                    const data = e.editor.getData();
                    setSpecs(data);
                  }}
                />
                <label htmlFor="french_specs">French Specs</label>

                <CKEditor
                  initData={frenchSpecs}
                  onChange={(e) => {
                    const data = e.editor.getData();
                    setFrenchSpecs(data);
                  }}
                />
              </div>
              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default AdminSubscriptionTypes;
