import React from "react";
import {ThemeProvider, createTheme} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

const CustomMuiDataTable = (props) => {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#AAF",
          },
          paper: {
            boxShadow: "none",
          },
        },

        MUIDataTableHeadCell: {
          root: {
            fontSize: "14px", // Adjust the font size of the header cells
            fontWeight: "bold",
            textAlign: "center", // Center align text in header cells
          },
        },

        MUIDataTableBodyCell: {
          root: {
            fontSize: "14px", // Adjust the font size here
            minWidth: "max-content",
            textAlign: "center",
          },
        },
        MuiTableCell: {
          root: {
            padding: "8px", // Adjust the cell padding
            textAlign: "center",
          },
          head: {
            backgroundColor: "#d3d3d3 !important",
            textAlign: "center",

            "& span": {
              fontWeight: "bold",
              fontSize: "14px",
              textAlign: "center",
              margin: "auto",
              justifyContent: "center",

              "& button": {
                minWidth: "max-content",
                textAlign: "center",
                "& span": {
                  textAlign: "center",
                  "& div": {
                    textAlign: "center",
                    margin: "auto",
                  },
                },
              },
              "& div": {
                minWidth: "max-content",
                textAlign: "center",
              },
            },
            fontSize: "14px",
            fontWeight: "bold",
            minWidth: "max-content",
          },
        },
      },
    });

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable {...props} />
    </ThemeProvider>
  );
};

export default CustomMuiDataTable;
