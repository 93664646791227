import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import {generateForm} from "../../helpers/functions";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Salesmen = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [couponsData, setCouponsData] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    id: -1,
  });
  const [couponsForm, setCouponsForm] = useState({
    salesmen_id: "",
    number_of_coupons: "",
    expiry: "",
    coupons_discount: "",
  });

  const [couponsModal, setCouponsModal] = useState(false);
  const [viewCouponsModal, setViewCouponsModal] = useState(false);

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/salesmen/get`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addForm = () => {
    setVisible(!visible);
    resetForm();
  };

  const updateFrom = (meta) => {
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/salesmen/getById/${id}`,
    })
      .then((response) => {
        setFormData({
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          id: id,
        });
        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    if (formData.id > 0) {
      const submitFormData = generateForm(formData);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/salesmen/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      const submitFormData = generateForm(formData);
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/salesmen/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/salesmen/delete`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const saveFormCoupon = () => {
    setSaveIsLoading(true);
    const submitFormData = generateForm(couponsForm);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/admin/salesmen/generateCoupons`,
      data: submitFormData,
    })
      .then((response) => {
        toast.success(`Updated successfully`);
        setSaveIsLoading(false);
        handleCloseCouponsModal();
        getData();
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error(`Error While Saving`);
      });
  };

  const handleInputChange = (e) => {
    const {id, name, value, checked, type} = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleInputChangeCoupon = (e) => {
    const {id, name, value, checked, type} = e.target;
    if (type === "checkbox") {
      setCouponsForm({
        ...couponsForm,
        [id]: checked,
      });
    } else {
      setCouponsForm({
        ...couponsForm,
        [id]: value,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone: "",
      id: -1,
    });
  };

  const handleClose = () => {
    setVisible(false);
  };

  const handleCloseCouponsModal = () => {
    setCouponsModal(false);
    setCouponsForm({
      salesmen_id: "",
      number_of_coupons: "",
      expiry: "",
      coupons_discount: "",
    });
  };

  const handleCloseViewCouponsModal = () => {
    setViewCouponsModal(false);
  };

  const generateCoupons = (id) => {
    setCouponsForm({
      salesmen_id: id,
      number_of_coupons: "",
      expiry: "",
      coupons_discount: "",
    });
    setCouponsModal(true);
  };

  const viewCoupons = (id) => {
    axios
      .get(`${process.env.REACT_APP_API}/admin/salesmen/getCouponsBySalesmen`, {
        params: {
          salesmen_id: id,
        },
      })
      .then(function (response) {
        setCouponsData(response.data);
      })
      .catch(function (error) {
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });
    setViewCouponsModal(true);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "total_coupons",
      label: "Total Coupons",
      options: {
        customBodyRender: (value, meta) => {
          return (
            <span
              style={{cursor: "pointer", fontWeight: "bolder"}}
              onClick={() => {
                viewCoupons(meta.rowData[0]);
              }}
            >
              {value}
            </span>
          );
        },
      },
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteForm(meta);
                }}
              >
                Delete
              </button>
              <button
                className="btn btn-info mx-1"
                onClick={() => {
                  generateCoupons(meta.rowData[0]);
                }}
              >
                Generate Coupons
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  const couponsColumns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "coupon_number",
      label: "Coupon Number",
    },
    {
      name: "coupons_discount",
      label: "Discount",
    },
    {
      name: "coupons_expiry_date",
      label: "Expiry date",
    },
    {
      name: "user_name",
      label: "User",
    },
    {
      name: "used_date",
      label: "Used Date",
    },
  ];

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Salemen"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>
          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    onChange={handleInputChange}
                    value={formData.name}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="email">email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    onChange={handleInputChange}
                    value={formData.email}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="phone">phone</label>
                  <input
                    type="phone"
                    className="form-control"
                    id="phone"
                    onChange={handleInputChange}
                    value={formData.phone}
                  />
                </div>
              </div>
              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          fullScreen
          open={couponsModal}
          onClose={handleCloseCouponsModal}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleCloseCouponsModal}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">Generate Coupons</h4>
            </Toolbar>
          </AppBar>
          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="number_of_coupons">Number Of Coupons</label>
                  <input
                    type="text"
                    className="form-control"
                    id="number_of_coupons"
                    onChange={handleInputChangeCoupon}
                    value={couponsForm.number_of_coupons}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="expiry">Expiry Days (FROM NOW)</label>
                  <input
                    type="number"
                    className="form-control"
                    id="expiry"
                    onChange={handleInputChangeCoupon}
                    value={couponsForm.expiry}
                  />
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div className="form-group">
                  <label htmlFor="coupons_discount">Discount %</label>
                  <input
                    type="number"
                    className="form-control"
                    id="coupons_discount"
                    onChange={handleInputChangeCoupon}
                    value={couponsForm.coupons_discount}
                  />
                </div>
              </div>
              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveFormCoupon}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          fullScreen
          open={viewCouponsModal}
          onClose={handleCloseViewCouponsModal}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleCloseViewCouponsModal}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">Salesmen Coupons</h4>
            </Toolbar>
          </AppBar>
          <div style={{padding: "25px"}}>
            <CustomMuiDataTable data={couponsData} columns={couponsColumns} options={options} />
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default Salesmen;
