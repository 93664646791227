import React, {useState, useEffect} from "react";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {FileUploader} from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import {CircularProgress} from "@material-ui/core";
import {generateForm} from "../../helpers/functions";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const AdminFeedAd = ({children, match, history}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState([]);
  const [viewedFile, setViewedFile] = useState([]);

  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [link, setLink] = useState("");
  const [ADid, setADid] = useState("");

  const saveForm = () => {
    // setSaveIsLoading(true);
    let formData = {link};
    const submitFormData = generateForm(formData);

    file?.map((fileData) => {
      submitFormData.append("file[]", fileData.file);
    });

    if (ADid) {
      submitFormData.append("id", ADid);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/mobile/otherApis/updateFeedAd`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          getData();
        })
        .catch((error) => console.log(error));
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/mobile/otherApis/addFeedAd`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Added successfully`);
          getData();
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response.data.error);
        });
    }
  };

  const getData = () => {
    setIsLoading(true);

    axios
      .get(`${process.env.REACT_APP_API}/mobile/otherApis/getFeedAd`)
      .then((response) => {
        console.log(response.data);
        setLink(response.data.link);
        setADid(response.data.id);
        if (response.data.image_name) {
          let temp1 = [];

          temp1.push({
            id: response.data.id,
            path: `${process.env.REACT_APP_FILES_PATH}/adsImages/${response.data.image_name}`,
          });

          setViewedFile(temp1);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/mobile/otherApis/deleteFeedAdImage`,
            data: {id: id},
          })
            .then((response) => {
              toast.success(`Image Deleted successfully`);
              setFile([]);
              setViewedFile([]);
              getData();
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      setFile([]);
      setViewedFile([]);
    }
  };

  const handleChangeFile = async (originalFile) => {
    const imgFile = await imageCompression(originalFile, options);
    const fileData = imgFile;
    if (fileData.type.match("image.*")) {
      const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
      let temp2 = [];
      file.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFile(temp2);

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFile.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFile(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const {id, value} = e.target;

    setLink(value);
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="row ">
          <div className={"col-sm-12"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                Save
              </button>
            )}
          </div>

          <label htmlFor="name">Link</label>
          <input type="text" className="form-control" id="link" onChange={handleInputChange} value={link} />

          {!viewedFile.length > 0 && (
            <FileUploader
              multiple={false}
              classes="w-100"
              handleChange={handleChangeFile}
              name="file"
              types={fileTypes}
            >
              <div
                className="w-100"
                style={{
                  minHeight: "25vh",
                  border: "2px dashed #ced4da",
                  padding: "10px",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <p>UPLOAD OR DROP AD IMAGE HERE</p>
              </div>
            </FileUploader>
          )}
          {viewedFile?.map((imageData, index) => {
            return (
              <div className="col-sm-2" key={index}>
                <img src={imageData.path} className="w-100 mt-3" />
                <button
                  className="btn btn-danger"
                  style={{position: "absolute"}}
                  onClick={() => {
                    removeImage(imageData.id);
                  }}
                >
                  X
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminFeedAd;
