import React, {useState, useEffect} from "react";
import SearchIcon from "@mui/icons-material/Search";
import {Popper} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import {FileUploader} from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import Rating from "@mui/material/Rating";
import AdminLayout from "./AdminLayout";
import {generateForm} from "../../helpers/functions";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminReviews = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [viewedFile, setViewedFile] = useState([]);
  const [file, setFile] = useState([]);

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    stars: "",
    review: "",
    frenchReview: "",
  });

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/reviews/get`, {})
      .then(function (response) {
        setData(response.data);

        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  const addForm = () => {
    setVisible(!visible);
    resetForm();
  };

  const updateFrom = (meta) => {
    resetForm();
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/reviews/getReview/${id}`,
    })
      .then((response) => {
        setFormData({
          name: response.data.name,
          stars: response.data.stars,
          review: response.data.review,
          frenchReview: response.data.frenchReview,
          id: id,
        });
        if (response.data.image_name) {
          let temp1 = [
            {
              id,
              path: `${process.env.REACT_APP_FILES_PATH}/reviews/${response.data.image_name}`,
            },
          ];

          setViewedFile(temp1);
        }

        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    if (formData.id > 0) {
      const submitFormData = generateForm(formData);
      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/reviews/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      const submitFormData = generateForm(formData);
      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/reviews/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/reviews/delete`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };
  const removeImage = (id) => {
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/reviews/deleteImage`,
            data: {id: id},
          })
            .then((response) => {
              let arr = file;
              let arr2 = viewedFile;
              arr = arr.filter((v) => {
                return v.id != id;
              });
              arr2 = arr2.filter((v) => {
                return v.id != id;
              });
              setFile(arr);
              setViewedFile(arr2);
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = file;
      let arr2 = viewedFile;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFile(arr);
      setViewedFile(arr2);
    }
  };
  const handleInputChange = (e) => {
    let {id, value, innerText} = e.target;
    if (!id) return;
    value = id.includes("option") ? innerText : value;
    id = id.includes("mui") ? "stars" : id;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleChangeFile = async (originalFile) => {
    const imgFile = await imageCompression(originalFile, options);
    const fileData = imgFile;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      let temp2 = [];
      file.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFile(temp2);
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFile.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFile(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      stars: "",
      review: "",
      frenchReview: "",
      id: -1,
    });

    setViewedFile([]);
    setFile([]);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "stars",
      label: "Stars",
    },
    {
      name: "review",
      label: "Review",
    },
    // {
    //   name: "frenchReview",
    //   label: "French Review",
    // },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteForm(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Testimonials"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>

          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  {!viewedFile.length > 0 && (
                    <FileUploader
                      multiple={false}
                      classes="w-100"
                      handleChange={handleChangeFile}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        className="w-100"
                        style={{
                          minHeight: "25vh",
                          border: "2px dashed #ced4da",
                          padding: "10px",
                          textAlign: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <p>DROP Image here</p>
                      </div>
                    </FileUploader>
                  )}
                  {viewedFile?.map((imageData, index) => {
                    return (
                      <div className="col-sm-2" key={index}>
                        <img src={imageData.path} className="w-100 mt-3" />
                        <button
                          className="btn btn-danger"
                          style={{position: "absolute"}}
                          onClick={() => {
                            removeImage(formData.id);
                          }}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                  <div>
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      onChange={handleInputChange}
                      value={formData.name}
                    />
                  </div>

                  <div>
                    <label htmlFor="name">Review</label>
                    <input
                      type="text"
                      className="form-control"
                      id="review"
                      onChange={handleInputChange}
                      value={formData.review}
                    />
                  </div>

                  {/* <div>
                    <label htmlFor="name">French Review</label>
                    <input
                      type="text"
                      className="form-control"
                      id="frenchReview"
                      onChange={handleInputChange}
                      value={formData.frenchReview}
                    />
                  </div> */}

                  <div style={{marginTop: "20px", marginBottom: "20px"}}>
                    <div>
                      <label htmlFor="name">Rating</label>
                    </div>
                    <div>
                      <Rating
                        size="large"
                        id="stars"
                        name="simple-controlled"
                        value={formData.stars}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default AdminReviews;
