import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import {CSVLink} from "react-csv";
import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminChats = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState("");
  const [firstUser, setFirstUser] = useState("");
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [chatData, setChatData] = useState("");
  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/chats/get`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addForm = () => {
    setVisible(!visible);
  };

  const viewFrom = (meta) => {
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/chats/getMessages/${id}`,
    })
      .then((response) => {
        setMessages(response.data);
        setFirstUser(response.data[0].user_id);
        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  var saveData = (function () {
    var a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    return function (data, fileName) {
      var json = JSON.stringify(data),
        blob = new Blob([json], {type: "octet/stream"}),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const exportChatMessages = (meta) => {
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/chats/getMessages/${id}`,
    })
      .then((response) => {
        let data = response.data;

        setChatData(data);
        // let fileName = "chats.txt";
        // saveData(chat, fileName);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/countries/delete`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const getChatData = (event, done, meta) => {
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/chats/getMessages/${id}`,
    })
      .then((response) => {
        let data = response.data;
        setChatData(data);

        done(true);
        setChatData("");
      })
      .catch((error) => {
        toast.error(`Error`);
        done(false);
      });
  };

  const dataFromChatState = () => {
    return chatData;
  };

  const handleClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "customer",
      label: "Customer",
    },
    {
      name: "vendor",
      label: "Vendor",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  viewFrom(meta);
                }}
              >
                View
              </button>

              {chatData && chatData[0].chat_id == meta.rowData[0] ? (
                <CSVLink
                  target="_blank"
                  style={{marginLeft: 20}}
                  filename={"Chat.csv"}
                  data={chatData}
                  onClick={() => setChatData("")}
                >
                  download
                </CSVLink>
              ) : (
                <button
                  className="btn btn-success mx-1"
                  onClick={() => {
                    exportChatMessages(meta);
                  }}
                >
                  Export
                </button>
              )}
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"countries"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">Chat Messages</h4>
            </Toolbar>
          </AppBar>
          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                {messages &&
                  messages?.map((x, i) => (
                    <div
                      style={{
                        width: "fit-content",
                        marginBottom: 20,
                        marginLeft: x.user_id == firstUser ? 0 : "50% ",
                      }}
                    >
                      <h4 style={{margin: 0}}>
                        {x.user_name} : {x.message}
                      </h4>
                      <i style={{fontSize: 12}}>{x.created_at}</i>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default AdminChats;
