import React, {useState, useEffect} from "react";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {FileUploader} from "react-drag-drop-files";
import {CircularProgress} from "@material-ui/core";
import imageCompression from "browser-image-compression";
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const fileTypes = ["JPG", "PNG", "JPEG"];

const AdminPDF = ({children, match, history}) => {
  const [files, setFiles] = useState([]);
  const [secondFiles, setSecondFiles] = useState([]);

  const [attachment1, setAttachment1] = useState({
    path: `${process.env.REACT_APP_FILES_PATH}/pdfs/customerDocument.pdf`,
  });
  const [attachment2, setAttachment2] = useState({
    path: `${process.env.REACT_APP_FILES_PATH}/pdfs/workerDocument.pdf`,
  });

  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const saveForm = () => {
    setSaveIsLoading(true);
    const submitFormData = new FormData();

    if (attachment1 && attachment1 !== null && attachment1.file) {
      submitFormData.append("attachment1", attachment1.file);
    }
    if (attachment2 && attachment2 !== null && attachment2.file) {
      submitFormData.append("attachment2", attachment2.file);
    }
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/admin/pdf/update`,
      data: submitFormData,
    })
      .then((response) => {
        toast.success(`Success`);
        console.log(response);
        setSaveIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSaveIsLoading(false);
      });
  };

  const handleFileChange = (e, a) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (a == 1) {
        setAttachment1((prev) => ({...prev, file: file, path: reader.result}));
      }
      if (a == 2) {
        setAttachment2((prev) => ({...prev, file: file, path: reader.result}));
      }
    };
  };
  return (
    <AdminLayout>
      <div className="container" style={{marginBottom: 100}}>
        <div className="row admin-aboutus-row" style={{padding: 10, marginBottom: 50}}>
          <div style={{marginTop: 20}}>
            <h3>PDF</h3>

            <div className="row">
              <label htmlFor="file">Customer Attachement</label>
              <div className={"col-sm-12"}>
                <input
                  className="mb-3"
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e) => handleFileChange(e, 1)}
                />
                {/* <div className="d-flex flex-row">
                  {attachment1 && attachment1.path && (
                    <div className="d-flex flex-column align-items-center m-2">
                      <object data={attachment1.path} type="application/pdf" width="200" height="200"></object>
                      <a target="_blank" rel="noopener noreferrer" href={attachment1.path}>
                        check
                      </a>
                    </div>
                  )}
                </div> */}
                {attachment1.path && (
                  <object width="100%" height="400" data={attachment1.path} type="application/pdf"></object>
                )}
              </div>
            </div>

            <div className="row">
              <label htmlFor="file">Worker Attachement</label>
              <div className={"col-sm-12"}>
                <input
                  className="mb-3"
                  type="file"
                  name="file"
                  id="file"
                  onChange={(e) => handleFileChange(e, 2)}
                />
                {/* <div className="d-flex flex-row">
                  {attachment2 && attachment2.path && (
                    <div className="d-flex flex-column align-items-center m-2">
                      <object data={attachment2.path} type="application/pdf" width="200" height="200"></object>
                      <a target="_blank" rel="noopener noreferrer" href={attachment2.path}>
                        check
                      </a>
                    </div>
                  )}
                </div> */}
                {attachment2.path && (
                  <object width="100%" height="400" data={attachment2.path} type="application/pdf"></object>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-sm-12"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminPDF;
