import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@mui/material/Autocomplete";
import { Dialog, Slide, AppBar, Toolbar, CircularProgress } from "@material-ui/core";
import { FileUploader } from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import { generateForm } from "../../helpers/functions";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { geocodeByAddress, getLatLng, geocodeByPlaceId } from "react-places-autocomplete";
import EditPost from "src/Components/EditPost";
import { Box, Modal } from "@mui/material";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
import SingleCustomAutocomplete from "src/Components/CustomAutocomplete/SingleCustomAutocomplete";

const fileTypes = ["JPG", "PNG", "JPEG"];
const licensesFilesTypes = ["JPG", "PNG", "JPEG", "PDF"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminVendors = ({ children, match, history }) => {
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState("");
  const [selectedcountry, setselectedCountry] = useState("");

  const [governorates, setGovernorates] = useState("");
  const [selectedgovernorate, setselectedGovernorate] = useState("");

  const [regions, setRegions] = useState("");
  const [selectedregion, setselectedRegion] = useState("");

  const [categories, setCategories] = useState("");
  const [selectedcategories, setselectedCategories] = useState([]);

  const [viewedFile, setViewedFile] = useState([]);
  const [file, setFile] = useState([]);

  const [viewedIdFile, setViewedIdFile] = useState([]);
  const [idFile, setIdFile] = useState([]);

  const [address, setAddress] = useState("");

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    profile_description: "",
    country_id: "",
    governorate_id: "",
    region_id: "",
    address: "",
    categories: "",
    subscription_type_id: "",
    user_type: 1,
    id: -1,
    active: 0,
    lat: "",
    lon: "",
  });

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = useGoogle({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  });

  const fetchLatLon = async (value) => {
    const address = value?.description;
    const placeId = value?.place_id;

    if (address && placeId) {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setFormData((prev) => ({
        ...prev,
        lat: latLng.lat,
        lon: latLng.lng,
        address: address,
      }));
    }
  };

  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/users/getVenders`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addForm = () => {
    setVisible(!visible);
    resetForm();
  };

  const [licensesFiles, setLicensesFiles] = useState([]);

  const removeLicense = (licenseId) => {
    if (licenseId > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/users/deleteLicense`,
            data: { id: licenseId },
          })
            .then((response) => {
              toast.success(`Success`);

              setLicensesFiles((prev) => prev.filter((v) => v.id != licenseId));
              setAttachments((prev) => prev.filter((v) => v.id != licenseId));
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      setLicensesFiles((prev) => prev.filter((v) => v.id != licenseId));
      setAttachments((prev) => prev.filter((v) => v.id != licenseId));
    }
  };
  const handleChangeLicenseFile = async (originalFile, categoryID) => {
    if (originalFile && categoryID) {
      let temp2 = [...licensesFiles];
      let temp = [...attachments];

      Object.values(originalFile).map(async (e) => {
        let fileData = e;

        const fileType = e.type;

        if (fileType.startsWith("image/") || fileType.startsWith("application/pdf")) {
          const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);

          if (fileType.startsWith("image/")) {
            const file = await imageCompression(e, options);

            fileData = file;
          }

          temp2.push({ id: randomId, file: fileData, category_id: categoryID });

          setLicensesFiles(temp2);

          const reader = new FileReader();
          reader.readAsDataURL(fileData);

          reader.onloadend = function (e) {
            const filePath = reader.result;

            temp.push({
              id: randomId,
              category_id: categoryID,
              // path: fileData,
              path: filePath,
              type: fileType.startsWith("image/")
                ? "image"
                : fileType.startsWith("application/pdf")
                  ? "pdf"
                  : "none",
            });
          };
          // }
        } else {
          toast("Only Images or PDFs Can be Uploaded", {
            position: "top-right",
            type: "error",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
      const result = await new Promise((resolve) =>
        setTimeout(() => resolve("Second Function Result"), 2000)
      );

      setAttachments(temp);
    }
  };

  const [posts, setPosts] = useState([]);

  const [id, setId] = useState(0);
  const updateFrom = (meta) => {
    resetForm();
    const workerId = meta ? meta.rowData[0] : id;

    setId(workerId);
    getUser(workerId);
  };

  const getUser = (userId) => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/users/getById/${userId}`,
    })
      .then((response) => {
        setFormData({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email,
          password: "",
          profile_description: response.data.profile_description,
          phone: response.data.phone,
          country_id: response.data.country_id,
          governorate_id: response.data.governorate_id,
          region_id: response.data.region_id,
          address: response.data.address,
          categories: response.data.categories,
          subscription_type_id: response.data.subscription_type_id,
          active: response.data.active,
          id: response.data.id,

          // lat: response.data.lat,
          // lon: response.data.lon,

          user_type: 1,
        });
        setAddress({ description: response.data.address });
        setAttachments(response.data.attachments);
        setLicensesFiles([]);
        setPosts(response.data.posts);
        setselectedCountry(countries.find((country) => country.id == response.data.country_id));
        setselectedGovernorate(
          governorates.find((governorate) => governorate.id == response.data.governorate_id)
        );
        setselectedRegion(regions.find((region) => region.id == response.data.region_id));

        const categoriesArr = response.data.categories.split(",");

        const selectedcategoriesArr = [];
        categoriesArr?.map((item) => {
          let found = categories.find((category) => category.id == parseInt(item));
          if (found) {
            selectedcategoriesArr.push(found);
          }
        });

        setselectedCategories(selectedcategoriesArr);

        let temp1 = [];
        if (response.data.profile_image_name)
          temp1.push({
            id: response.data.id,
            path: `${process.env.REACT_APP_FILES_PATH}/userProfiles/${response.data.profile_image_name}`,
          });

        setViewedFile(temp1);

        let temp2 = [];
        if (response.data.id_image_name)
          temp2.push({
            id: response.data.id,
            path: `${process.env.REACT_APP_FILES_PATH}/userProfiles/${response.data.id_image_name}`,
          });

        setViewedIdFile(temp2);

        setVisible(true);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const deletePost = (postId) => {
    Swal.fire({
      title: "Are you sure you want to delete this post ? ",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          accept: "any",
          url: `${process.env.REACT_APP_API}/admin/users/deletePost`,
          data: { id: postId },
        })
          .then((response) => {
            updateFrom();
          })
          .catch((error) => { });
      }
    });
  };

  const saveForm = () => {
    setSaveIsLoading(true);

    const submitFormData = generateForm(formData);
    file?.map((fileData) => {
      submitFormData.append("file[]", fileData.file);
    });

    idFile?.map((fileData) => {
      submitFormData.append("idFile[]", fileData.file);
    });
    licensesFiles?.map((fileData) => {
      submitFormData.append(`licensesFiles_${fileData.category_id}[]`, fileData.file);
    });
    if (formData.id > 0) {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/users/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/users/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const deactivateForm = (meta) => {
    const id = meta ? meta.rowData[0] : formData.id;
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Deactivate",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/users/deactivate`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
            setFormData((prev) => ({
              ...prev,
              active: 0,
            }));
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const activateForm = (meta) => {
    const id = meta ? meta.rowData[0] : formData.id;
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Ativate",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/users/activate`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
            setFormData((prev) => ({
              ...prev,
              active: 1,
            }));
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const deleteUser = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/users/delete`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const removeImage = (id, type) => {
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/users/deleteProfileImage`,
            data: { id: id, type: type },
          })
            .then((response) => {
              if (type == "profile") {
                let arr = file;
                let arr2 = viewedFile;
                arr = arr.filter((v) => {
                  return v.id != id;
                });
                arr2 = arr2.filter((v) => {
                  return v.id != id;
                });
                setFile(arr);
                setViewedFile(arr2);
              }

              if (type == "id") {
                let arr = idFile;
                let arr2 = viewedIdFile;
                arr = arr.filter((v) => {
                  return v.id != id;
                });
                arr2 = arr2.filter((v) => {
                  return v.id != id;
                });
                setIdFile(arr);
                setViewedIdFile(arr2);
              }

              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      if (type == "profile") {
        let arr = file;
        let arr2 = viewedFile;
        arr = arr.filter((v) => {
          return v.id != id;
        });
        arr2 = arr2.filter((v) => {
          return v.id != id;
        });
        setFile(arr);
        setViewedFile(arr2);
      }

      if (type == "id") {
        let arr = idFile;
        let arr2 = viewedIdFile;
        arr = arr.filter((v) => {
          return v.id != id;
        });
        arr2 = arr2.filter((v) => {
          return v.id != id;
        });
        setIdFile(arr);
        setViewedIdFile(arr2);
      }
    }
  };
  const handleInputChange = (e) => {
    const {
      id,

      value,
      checked,
      type,
    } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked ? 1 : 0,
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  const removeAttachment = (id) => {
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/mobile/posts/deleteImage`,
            data: { id: id },
          })
            .then((response) => {
              getUser(formData.id);
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
    }
  };

  const changeAutoCompleteValue = (value, type) => {
    const newForm = formData;
    newForm[type] = value.toString();
    setFormData(newForm);
  };

  const handleChangeFile = async (originalFile, type) => {
    const imgFile = await imageCompression(originalFile, options);
    const fileData = imgFile;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(-Math.random() * (10000 - 1000 + 1)) + 1000;
      let temp2 = [];

      temp2.push({ id: randomId, file: fileData });

      if (type == "profile") {
        setFile(temp2);

        file.forEach((element) => {
          temp2.push(element);
        });
      }
      if (type == "id") {
        setIdFile(temp2);

        idFile.forEach((element) => {
          temp2.push(element);
        });
      }

      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];

        temp.push({ id: randomId, path: fileData, path: imagePath });
        if (type == "profile") {
          viewedFile.forEach((element) => {
            temp.push(element);
          });

          setViewedFile(temp);
        }
        if (type == "id") {
          viewedIdFile.forEach((element) => {
            temp.push(element);
          });

          setViewedIdFile(temp);
        }
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      password: "",
      profile_description: "",
      country_id: "",
      governorate_id: "",
      region_id: "",
      address: "",
      categories: "",
      subscription_type_id: "",
      active: 0,
      id: -1,
      // lat: "",
      // lon: "",
      user_type: 1,
    });

    setViewedFile([]);
    setFile([]);
    setIdFile([]);
    setselectedCategories([]);

    setAddress("");
  };

  const handleClose = () => {
    setVisible(false);
  };

  const sendVerification = (meta) => {
    const id = meta.rowData[0];
    const email = meta.rowData[3];
    const token = meta.rowData[6];

    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_API}/mail/send-email-verification`,
    //   data: {
    //     email: email,
    //     verification_token: token,
    //   },
    // })
    //   .then((res) => {
    //     console.log(res.data);
    //     toast.success(`Success`);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     toast.error(`Error`);
    //   });

    const submitFormData = new FormData();
    submitFormData.append("id", id);
    submitFormData.append("email", email);
    submitFormData.append("email_verified_at", new Date().toDateString());

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/admin/users/update`,
      data: submitFormData,
    })
      .then((response) => {
        toast.success(`Verified`);
        setSaveIsLoading(false);

        getData();
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error(`Error While Saving`);
      });
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "first_name",
      label: "First Name",
    },
    {
      name: "last_name",
      label: "Last Name",
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "phone",
      label: "Phone",
    },
    {
      name: "governorate_name",
      label: "City",
    },
    {
      name: "address",
      label: "Address",
    },
    {
      name: "active",
      label: "Active",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value == 1 ? true : false}
            />
          );
        },
      },
    },
    {
      name: "verification_token",
      label: "verification_token",
      options: {
        display: false,
      },
    },

    {
      name: "email_verified_at",
      label: "email_verified_at",
      options: {
        display: false,
      },
    },

    {
      name: "active",
      label: "ACTIVATION",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            // <div>
            //   {meta.rowData[5] == 0 ? (
            //     <button
            //       className="btn btn-success mx-1"
            //       onClick={() => {
            //         activateForm(meta);
            //       }}
            //     >
            //       Activate
            //     </button>
            //   ) : (
            //     <button
            //       className="btn btn-danger mx-1"
            //       onClick={() => {
            //         deactivateForm(meta);
            //       }}
            //     >
            //       Deactivate
            //     </button>
            //   )}
            // </div>
            <div>
              {value == 0 ? (
                <button
                  className="btn btn-success mx-1"
                  onClick={() => {
                    activateForm(meta);
                  }}
                >
                  Activate
                </button>
              ) : (
                <button
                  className="btn btn-danger mx-1"
                  onClick={() => {
                    deactivateForm(meta);
                  }}
                >
                  Deactivate
                </button>
              )}
            </div>
          );
        },
      },
    },

    {
      name: "email_verified_at",
      label: "VERIFICATION",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              {!value ? (
                <button
                  className="btn btn-success mx-1"
                  onClick={() => {
                    sendVerification(meta);
                  }}
                >
                  {/* Send Verification */}
                  Verify
                </button>
              ) : (
                <span>Verified</span>
              )}
            </div>
          );
        },
      },
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>

              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteUser(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API}/noAuthApi/getSignUpData`, {})
      .then(function (response) {
        const data = response.data;
        setCategories(data.Categories);
        setCountries(data.Countries);
        setGovernorates(data.Governorates);
        setRegions(data.Regions);
      })
      .catch((err) => console.log(err));
  }, []);

  const [editPost, setEditPost] = useState(false);
  const [targetPost, setTargetPost] = useState(null);
  const openEditPost = (x) => {
    setTargetPost(x);
    setEditPost(true);
  };
  const closeEditPost = () => setEditPost(false);

  const editPostStyle = {
    margin: "auto",
    marginTop: "20vh",
    maxWidth: 800,
    bgcolor: "white",
    border: "2px solid #D62221",

    p: 4,
  };
  const customOnChange = (newValue, name) => {
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable
          // responsive="scrollFullHeight"
          title={"Workers"}
          data={data}
          columns={columns}
          options={options}
        />

        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{ zIndex: 2235 }}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>
          {/* <Modal style={{zIndex: 1000}} className="customModal" open={editPost} onClose={closeEditPost}>
            <Box sx={editPostStyle}>
              {targetPost && (
                <EditPost
                  user={false}
                  getUserPosts={() => getUser(formData.id)}
                  post={targetPost}
                  closeEditPost={closeEditPost}
                  handleMenuClose={() => console.log("")}
                />
              )}
            </Box>
          </Modal> */}
          <div style={{ padding: "25px" }}>
            <div className="row">
              <div className={"col-sm-6"}>
                <label htmlFor="">Profile image</label>

                <div className="form-group">
                  {!viewedFile || viewedFile?.length == 0 ? (
                    <FileUploader
                      multiple={false}
                      classes="w-100"
                      handleChange={(e) => handleChangeFile(e, "profile")}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        className="w-100"
                        style={{
                          minHeight: "25vh",
                          border: "2px dashed #ced4da",
                          padding: "10px",
                          textAlign: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <p>DROP YOUR ICON HERE</p>
                      </div>
                    </FileUploader>
                  ) : (
                    <></>
                  )}
                  {viewedFile?.map((imageData, index) => {
                    return (
                      <div className="col-sm-2" key={index}>
                        <img src={imageData.path} className="w-100 mt-3" />
                        <button
                          className="btn btn-danger"
                          style={{ position: "absolute" }}
                          onClick={() => {
                            removeImage(imageData.id, "profile");
                          }}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={"col-sm-6"}>
                <label htmlFor="">ID image</label>

                {!viewedIdFile || viewedIdFile?.length == 0 ? (
                  <FileUploader
                    multiple={false}
                    classes="w-100"
                    handleChange={(e) => handleChangeFile(e, "id")}
                    name="file"
                    types={fileTypes}
                  >
                    <div
                      className="w-100"
                      style={{
                        minHeight: "25vh",
                        border: "2px dashed #ced4da",
                        padding: "10px",
                        textAlign: "center",
                        marginTop: "1rem",
                      }}
                    >
                      <p>DROP YOUR ICON HERE</p>
                    </div>
                  </FileUploader>
                ) : (
                  <></>
                )}

                {viewedIdFile?.map((imageData, index) => {
                  return (
                    <div className="col-sm-2" key={index}>
                      <img src={imageData.path} className="w-100 mt-3" />
                      <button
                        className="btn btn-danger"
                        style={{ position: "absolute" }}
                        onClick={() => {
                          removeImage(imageData.id, "id");
                        }}
                      >
                        X
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="row">
              <div className={"col-sm-6"}>
                <label htmlFor="first_name">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="first_name"
                  onChange={handleInputChange}
                  value={formData.first_name}
                />
              </div>

              <div className={"col-sm-6"}>
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  onChange={handleInputChange}
                  value={formData.last_name}
                />
              </div>
            </div>

            <div className="row">
              <div className={"col-sm-6"}>
                <label htmlFor="email: ">Email</label>
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  onChange={handleInputChange}
                  value={formData.email}
                />
              </div>
              <div className={"col-sm-6"}>
                <label htmlFor="password">password</label>
                <input
                  type="text"
                  className="form-control"
                  id="password"
                  onChange={handleInputChange}
                  value={formData.password}
                />
              </div>{" "}
            </div>

            <div className="row">
              <div className={"col-sm-6"}>
                <label htmlFor="phone">Phone</label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  onChange={handleInputChange}
                  value={formData.phone}
                />{" "}
              </div>
              <div className={"col-sm-6"}>
                <label htmlFor="address">Address </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  onChange={handleInputChange}
                  value={formData.address}
                />
                {/* <Autocomplete
                  className="signup-selectfields"
                  disablePortal
                  id="address"
                  options={placePredictions}
                  getOptionLabel={(item) => (item?.description ? item.description : "")}
                  value={address}
                  onPlaceSelected={(place, inputRef, autocomplete) => {
                    console.log(place, inputRef, autocomplete);
                  }}
                  onChange={(event, value) => {
                    setAddress(value);
                    // getCountryAndRegion(value);
                    fetchLatLon(value);

                    changeAutoCompleteValue(value?.description ? value.description : "", "address");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Address"
                      required
                      id="address"
                      onChange={(e) => {
                        getPlacePredictions({ input: e.target.value });
                      }}
                      loading={isPlacePredictionsLoading}
                    />
                  )}
                /> */}
              </div>
            </div>
            <div className={"col-sm-6"} style={{marginTop:20}}>
                <div className="form-group">
                  <SingleCustomAutocomplete
                    filedName="governorate_id"
                    label="City"
                    list={governorates}
                    value={formData.governorate_id}
                    listKey="id"
                    description="name"
                    customOnChange={customOnChange}
                  />
                </div>
              </div>
            <label htmlFor="profile_description">Profile Description</label>
            <input
              type="text"
              className="form-control"
              id="profile_description"
              onChange={handleInputChange}
              value={formData.profile_description}
            />

            {countries &&
              // && governorates && regions
              categories && (
                <>
                  {/* <Autocomplete
                        style={{marginTop: 20}}
                        disablePortal
                        id="country_id"
                        options={countries}
                        getOptionLabel={(country) => (country.name ? country.name : "")}
                        value={selectedcountry}
                        onChange={(event, value) => {
                          setselectedCountry(value);
                          changeAutoCompleteValue(value.id, "country_id");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Countries" required id="Countries" />
                        )}
                      /> */}
                  {/* <Autocomplete
                        style={{marginTop: 20}}
                        disablePortal
                        id="governorate_id"
                        options={governorates}
                        getOptionLabel={(governorate) => (governorate.name ? governorate.name : "")}
                        value={selectedgovernorate}
                        onChange={(event, value) => {
                          setselectedGovernorate(value);
                          changeAutoCompleteValue(value.id, "governorate_id");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Governorates" required id="Governorates" />
                        )}
                      /> */}

                  <div className="row">
                    {/* <div className={"col-sm-6"}>
                      <Autocomplete
                        style={{marginTop: 20}}
                        disablePortal
                        id="region_id"
                        options={regions}
                        getOptionLabel={(region) => (region.name ? region.name : "")}
                        value={selectedregion}
                        onChange={(event, value) => {
                          setselectedRegion(value);
                          changeAutoCompleteValue(value.id, "region_id");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Regions" required id="region_id" />
                        )}
                      />{" "}
                    </div> */}
                    <div className={"col-sm-12"}>
                      <Autocomplete
                        style={{ marginTop: 20 }}
                        multiple
                        id="tags-outlined"
                        options={categories}
                        getOptionLabel={(category) => (category.name ? category.name : "")}
                        // getOptionSelected
                        filterSelectedOptions
                        value={selectedcategories ? selectedcategories : []}
                        onChange={(event, value) => {
                          setselectedCategories(value);
                          const values = value?.map((v) => v.id);
                          changeAutoCompleteValue(values, "categories");
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Categories" required id="categories" />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
            <div className="row">
              {selectedcategories?.map((category, index) => (
                <>
                  {!attachments.some((x) => x.category_id == category.id) ? (
                    <div style={{ width: "auto" }}>
                      <FileUploader
                        multiple
                        handleChange={(e) => handleChangeLicenseFile(e, category.id)}
                        name="file"
                        types={licensesFilesTypes}
                      >
                        <div className="id-image-uploader" style={{ textTransform: "uppercase", margin: 0 }}>
                          <p>LICENSE FOR {category.name}</p>
                        </div>
                      </FileUploader>
                    </div>
                  ) : (
                    <div key={index} className={"col-sm-4"} style={{ position: "relative" }}>
                      <button
                        className="btn btn-danger"
                        style={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => {
                          removeLicense(
                            attachments.find(
                              (x) => x.category_id == category.id || x.category?.id == category.id
                            ).id
                          );
                        }}
                      >
                        X
                      </button>

                      <label htmlFor={category.name}>License for {category.name}</label>
                      <div id={category.name}>
                        <object
                          width="100%"
                          height="200"
                          data={
                            attachments.find((x) => x.category_id == category.id)?.path ??
                            `${process.env.REACT_APP_FILES_PATH}/userFiles/${attachments.find((x) => x.category?.id == category.id)?.attachment_name
                            }`
                          }
                          type="application/pdf"
                        ></object>
                      </div>
                    </div>
                  )}
                </>
              ))}

              {/* {attachments?.map((attachment, index) => (
                <div key={index} className={"col-sm-4"}>
                  <label>License for {attachment.category.name}</label>
                  <div>
                    <object
                      width="100%"
                      height="200"
                      data={`${process.env.REACT_APP_FILES_PATH}/userFiles/${attachment.attachment_name}`}
                      type="application/pdf"
                    ></object>
                  </div>
                </div>
              ))} */}
            </div>

            {posts?.map((post, index) => (
              <div className="row" style={{ padding: "20px" }}>
                {targetPost && targetPost?.id == post.id && editPost ? (
                  <Box sx={editPostStyle}>
                    {targetPost && (
                      <EditPost
                        user={false}
                        getUserPosts={() => getUser(formData.id)}
                        post={targetPost}
                        closeEditPost={closeEditPost}
                        handleMenuClose={() => console.log("")}
                      />
                    )}
                  </Box>
                ) : (
                  <div className="profile-post-item" key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <button onClick={() => openEditPost(post)}>Edit</button>
                      </div>
                      <div>
                        <button onClick={() => deletePost(post.id)}>Delete</button>
                      </div>
                    </div>
                    <div className="profile-post-images">
                      {post.attachments?.map((attachement) => (
                        <div style={{ maxHeight: "300px", position: "relative" }}>
                          <button
                            className="btn btn-danger"
                            style={{ position: "absolute", top: 0, right: 0 }}
                            onClick={() => {
                              removeAttachment(attachement.id);
                            }}
                          >
                            X
                          </button>
                          {attachement.image_name.includes("mp4") ? (
                            <video controls style={{height:'200px'}}>
                              <source
                                src={`${process.env.REACT_APP_FILES_PATH}/posts/${attachement.image_name}`}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img
                              style={{ height: "400px",width:'100%',objectFit:'contain',marginBottom:'20px' }}
                              src={`${process.env.REACT_APP_FILES_PATH}/posts/${attachement.image_name}`}
                            />
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="profile-post-desc" style={{marginTop:'20px'}}>
                      <p>{post.description}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}

            <div className="row mt-5">
              {/* <div className={"col-sm-6"}>
                {formData.active == 0 ? (
                  <button
                    className="btn btn-success mx-1"
                    onClick={() => {
                      activateForm();
                    }}
                  >
                    Activate
                  </button>
                ) : (
                  <button
                    className="btn btn-danger mx-1"
                    onClick={() => {
                      deactivateForm();
                    }}
                  >
                    Deactivate
                  </button>
                )}
              </div> */}

              <div className={"col-sm-6"}>
                <div className="form-group" style={{ padding: "20px" }}>
                  <input
                    className="form-control-checkbox"
                    id="active"
                    type="checkbox"
                    checked={formData.active == 1}
                    onChange={handleInputChange}
                  />
                  <label style={{ paddingLeft: 10 }} htmlFor="active">
                    Active
                  </label>
                </div>
              </div>

              <div className={"col-sm-6"} style={{display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default AdminVendors;
