import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Dialog, Slide, AppBar, Toolbar, CircularProgress } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const AddUpdateReview = (props) => {
    const [data, setData] = useState([]);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        id: -1,
        review: "",
        vendor_id: null,
    });
    const resetForm = () => {
        setFormData({
            id: -1,
            review: "",
            vendor_id: null,
        });
    };

    const getFormData = () => {
        const id = props.id;
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API}/mobile/vendorReviews/getById-${id}`,
        })
            .then((response) => {
                setFormData({
                    review: response.data.review,
                    vendor_id: response.data.vendor_id,
                    id: id,
                });
            })
            .catch((error) => {
                toast.error(`Error`);
            });
    };

    const saveForm = () => {
        setSaveIsLoading(true);
        if (formData.id > 0) {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API}/mobile/vendorReviews/update`,
                data: formData,
            })
                .then((response) => {
                    toast.success(`Updated successfully`);
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false);
                    toast.error(`Error While Saving`);
                });
        } else {
            axios({
                method: "post",
                url: `${process.env.REACT_APP_API}/mobile/vendorReviews/add`,
                data: formData,
            })
                .then((response) => {
                    toast.success(`Inserted successfully`);
                    setSaveIsLoading(false);
                    props.onClose(true);
                })
                .catch((error) => {
                    setSaveIsLoading(false);
                    toast.error(`Error While Saving`);
                });
        }
    };

    const handleInputChange = (e) => {
        let { id, value, innerText } = e.target;
        if (!id) return;
        value = id.includes("option") ? innerText : value;
        id = id.includes("mui") ? "stars" : id;
        setFormData({
            ...formData,
            [id]: value,
        });
    };

    useEffect(() => {
        console.log(props.id);
        
        if (props.id > 0) {
            getFormData();
        }else{
            resetForm();
        }
    }, [props.open]);

    return (
        <Dialog
            fullScreen
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
            style={{ zIndex: 2235 }}
        >
            <AppBar className="position-relative">
                <Toolbar>
                    <button type="button" className="close-modal-button" onClick={() => props.onClose(false)}>
                        &times;
                    </button>
                    <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
                </Toolbar>
            </AppBar>

            <div style={{ padding: "25px" }}>
                <div className="row">
                    <div className={"col-sm-6"}>
                        <div className="form-group">
                            <div>
                                <label htmlFor="name">Review</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="review"
                                    onChange={handleInputChange}
                                    value={formData.review}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"col-sm-12 mt-5"}>
                        {saveIsLoading ? (
                            <CircularProgress />
                        ) : (
                            <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                                Save
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
export default AddUpdateReview;
