import React, {useState, useEffect} from "react";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import {FileUploader} from "react-drag-drop-files";
import {generateForm} from "../helpers/functions";
import {toast} from "react-toastify";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import {useDispatch, useSelector} from "react-redux";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import noProfileImg from "../assets/images/avatar.jpg";
import CircularProgress from "@mui/material/CircularProgress";
import imageCompression from "browser-image-compression";
import TextField from "@mui/material/TextField";

const EditPost = ({user, getUserPosts, post, closeEditPost, handleMenuClose}) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  const fileTypes = ["JPG", "PNG", "JPEG", "MP4"];
  const lan = useSelector((state) => state.currentLanguage);

  const [postOption, setPostOption] = useState(post.post_option);
  const [description, setDescription] = useState(post.description);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryId, setCategoryId] = useState(post.category_id);
  const [files, setFiles] = useState([]);
  const [viewedFiles, setViewedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      if (viewedFiles.length == 1) return;
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/mobile/posts/deleteImage`,
            data: {id: id},
          })
            .then((response) => {
              let msg = lan == "en" ? "Success" : "Succès";
              toast.success(msg);
              let arr = files;
              let arr2 = viewedFiles;
              arr = arr.filter((v) => {
                return v.id != id;
              });
              arr2 = arr2.filter((v) => {
                return v.id != id;
              });
              setFiles(arr);
              setViewedFiles(arr2);
            })
            .catch((error) => {
              let msg = lan == "en" ? "Error" : "Erreur";
              toast.error(msg);
            });
        }
      });
    } else {
      let arr = files;
      let arr2 = viewedFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFiles(arr);
      setViewedFiles(arr2);
    }
  };

  const updatePost = () => {
    setLoading(true);
    if (!description || !viewedFiles.length) {
      setLoading(false);
      let err1 = lan == "en" ? "Add at least one image" : "Ajouter au moins une image";
      let err2 = lan == "en" ? "Add a description" : "Ajouter une description";
      let err3 = lan == "en" ? "Please choose an option" : "Veuillez choisir une option";
      if (!files.length) toast.error(err1);
      if (!description) toast.error(err2);
      if (!postOption) toast.error(err3);
      return;
    }
    let formData = {
      description,
      post_option: postOption,
      category_id: categoryId,
    };
    const submitFormData = generateForm(formData);
    files?.map((fileData) => {
      submitFormData.append("files[]", fileData.file);
    });
    submitFormData.append("id", post.id);
    axios({
      method: "post",
      accept: "any",
      url: `${process.env.REACT_APP_API}/mobile/posts/update`,
      data: submitFormData,
    })
      .then((response) => {
        setLoading(false);
        getUserPosts();
        closeEditPost();
        handleMenuClose();
        let msg = lan == "en" ? "Success" : "Succès";
        toast.success(msg);
      })
      .catch((error) => {
        setLoading(false);
        let msg = lan == "en" ? "Fail" : "Échec";
        toast.error(msg);
      });
  };
  const handleChangeFiles = async (originalFile) => {
    const randomId = -Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;

    if (originalFile.type.match("image.*")) {
      const file = await imageCompression(originalFile, options);

      const fileData = file;

      let temp2 = [];
      files.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFiles(temp2);
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: imagePath});
        setViewedFiles(temp);
      };
    } else {
      let temp2 = [];
      files.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: originalFile});
      setFiles(temp2);

      const reader = new FileReader();
      const file = originalFile;

      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        const videoPath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: videoPath});
        setViewedFiles(temp);
      };

      // toast("Only Images Can be Uploaded", {
      //   position: "top-right",
      //   type: "error",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      // });
    }
  };
  const getCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API}/mobile/otherApis/getCategories`)
      .then(function (response) {
        let categories = response.data?.map((record) => ({
          section: "Categories",
          id: record.category.id,
          name: record.category.name,
          frenchName: record.category.frenchName,
          icon: record.icon && record.icon[0] && record.icon[0].icon_name ? record.icon[0].icon_name : "",
        }));

        setCategories(categories);

        let category = categories.find((category) => category.id == post.category_id);

        setSelectedCategory(category);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = () => {
    if (post) {
      let temp = [];
      if (post.post_images) {
        post.post_images.forEach((element, index) => {
          temp.push({
            id: element.id,
            path: `${process.env.REACT_APP_FILES_PATH}/posts/${element.image_name}`,
          });
        });
      }
      if (post.attachments) {
        post.attachments.forEach((element, index) => {
          temp.push({
            id: element.id,
            path: `${process.env.REACT_APP_FILES_PATH}/posts/${element.image_name}`,
          });
        });
      }
      setViewedFiles(temp);

      getCategories();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{position: "relative"}}>
        <div className="project-input-section">
          <div style={{display: "flex"}}>
            {user && (
              <img
                width="80"
                height="80"
                style={{borderRadius: "50%"}}
                alt="user"
                src={
                  user.profile_image_name
                    ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${user.profile_image_name}`
                    : noProfileImg
                }
              />
            )}

            <TextareaAutosize
              placeholder={"TALK ABOUT YOUR PROJECT"}
              style={{
                padding: 10,
                margin: "20px 0px 10px 20px",
                borderRadius: "5px",
                borderWidth: "2px",
                // border: "none",
                width: "70%",
                fontSize: "13px",
              }}
              minRows={viewedFiles.length > 0 ? 11 : 3}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="project-images" style={{position: viewedFiles.length > 0 ? "absolute" : ""}}>
            {viewedFiles &&
              viewedFiles?.map((fileData, index) => {
                return (
                  <div className="uploaded-project-image" key={index}>
                    {fileData.path.includes("video") || fileData.path.includes("mp4") ? (
                      <video src={fileData.path} />
                    ) : (
                      <img src={fileData.path} />
                    )}
                    <button
                      // className="btn btn-danger"
                      style={{
                        position: "absolute",
                        left: 0,
                        width: 20,
                        height: 20,
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                      }}
                      onClick={() => {
                        removeImage(fileData.id);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            <FileUploader multiple={false} handleChange={handleChangeFiles} name="file" types={fileTypes}>
              <div className="project-image-uploader">
                <h3>+</h3>
              </div>
            </FileUploader>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <div
          className="project-preview-publish"
          style={{
            justifyContent: "flex-end",
            height: !viewedFiles.length > 0 ? "78%" : "",
          }}
        >
          {viewedFiles.length > 0 ? (
            <>
              {viewedFiles[viewedFiles.length - 1].path.includes("video") ||
              viewedFiles[viewedFiles.length - 1].path.includes("mp4") ? (
                <div>
                  <video controls>
                    <source src={viewedFiles[viewedFiles.length - 1].path} type="video/mp4" />
                  </video>
                </div>
              ) : (
                <div>
                  <img src={viewedFiles[viewedFiles.length - 1].path} />
                </div>
              )}
              {/* <div style={{marginBottom: 10}}>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={postOption}
                  onChange={(e, v) => setPostOption(v)}
                >
                  <FormControlLabel value="commercial" control={<Radio />} label="commercial" />
                  <FormControlLabel value="residential" control={<Radio />} label="residential" />
                </RadioGroup>
              </div> */}
              <div>
                <Autocomplete
                  style={{marginBottom: 10}}
                  disablePortal
                  id="category_id"
                  options={categories}
                  getOptionLabel={(category) => (category.name ? category.name : "")}
                  value={selectedCategory}
                  onChange={(event, value) => {
                    setSelectedCategory(value);
                    setCategoryId(value.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      required
                      // id={lan == "en" ? "frenchName" : "name"}
                    />
                  )}
                />
              </div>
            </>
          ) : (
            <div></div>
          )}
          {loading ? (
            <div style={{marginTop: 20, textAlign: "center"}}>
              <CircularProgress />
            </div>
          ) : (
            <Button className="post-update-btn" variant="contained" onClick={updatePost}>
              <b>Update</b>
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

export default EditPost;
