import React, {useState, useEffect} from "react";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {FileUploader} from "react-drag-drop-files";
import {CircularProgress} from "@material-ui/core";
import imageCompression from "browser-image-compression";
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const fileTypes = ["JPG", "PNG", "JPEG"];

const AdminHomePage = ({children, match, history}) => {
  const [adminHomeImagesData, setAdminHomeImagesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [secondFiles, setSecondFiles] = useState([]);
  const [thirdFiles, setThirdFiles] = useState([]);
  const [fourthFiles, setFourthFiles] = useState([]);

  const [viewedFiles, setViewedFiles] = useState([]);
  const [viewedSecondFiles, setViewedSecondFiles] = useState([]);
  // const [viewedThirdFiles, setViewedThirdFiles] = useState([]);
  // const [viewedFourthFiles, setViewedFourthFiles] = useState([]);

  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    frenchTitle: "",
    description: "",
    frenchDescription: "",
    secondTitle: "",
    secondFrenchTitle: "",
    secondDescription: "",
    secondFrenchDescription: "",
    thirdTitle: "",
    thirdFrenchTitle: "",
    thirdDescription: "",
    thirdFrenchDescription: "",
    fourthTitle: "",
    fourthFrenchTitle: "",
    fourthDescription: "",
    fourthFrenchDescription: "",
    fifthTitle: "",
    fifthFrenchTitle: "",
    fifthDescription: "",
    fifthFrenchDescription: "",

    android_link: "",
    ios_link: "",
    id: -1,
  });

  const saveForm = () => {
    setSaveIsLoading(true);
    const submitFormData = new FormData();
    submitFormData.append("title", formData.title);
    submitFormData.append("frenchTitle", formData.frenchTitle);
    submitFormData.append("description", formData.description);
    submitFormData.append("frenchDescription", formData.frenchDescription);
    submitFormData.append("secondTitle", formData.secondTitle);
    submitFormData.append("secondFrenchTitle", formData.secondFrenchTitle);
    submitFormData.append("secondDescription", formData.secondDescription);
    submitFormData.append("secondFrenchDescription", formData.secondFrenchDescription);
    submitFormData.append("thirdTitle", formData.thirdTitle);
    submitFormData.append("thirdFrenchTitle", formData.thirdFrenchTitle);

    submitFormData.append("thirdDescription", formData.thirdDescription);
    submitFormData.append("thirdFrenchDescription", formData.thirdFrenchDescription);

    submitFormData.append("fourthTitle", formData.fourthTitle);
    submitFormData.append("fourthFrenchTitle", formData.fourthFrenchTitle);

    submitFormData.append("fourthDescription", formData.fourthDescription);
    submitFormData.append("fourthFrenchDescription", formData.fourthFrenchDescription);

    submitFormData.append("fifthTitle", formData.fifthTitle);
    submitFormData.append("fifthFrenchTitle", formData.fifthFrenchTitle);
    submitFormData.append("fifthDescription", formData.fifthDescription);

    submitFormData.append("fifthFrenchDescription", formData.fifthFrenchDescription);

    submitFormData.append("android_link", formData.android_link);
    submitFormData.append("ios_link", formData.ios_link);

    files?.map((fileData) => {
      submitFormData.append("files[]", fileData.file);
    });
    secondFiles?.map((fileData) => {
      submitFormData.append("secondFiles[]", fileData.file);
    });

    // thirdFiles?.map((fileData) => {
    //   submitFormData.append("thirdFiles[]", fileData.file);
    // });

    // fourthFiles?.map((fileData) => {
    //   submitFormData.append("fourthFiles[]", fileData.file);
    // });

    if (formData.id > 0) {
      submitFormData.append("id", formData.id);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/aboutus/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Success`);
          console.log(response);
          setSaveIsLoading(false);
        })
        .catch((error) => console.log(error));
    } else {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/aboutus/add`,
        data: submitFormData,
      })
        .then((response) => {
          setSaveIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          toast.error(`Error`);
          setSaveIsLoading(false);
        });
    }
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/aboutus/get`)
      .then((response) => {
        let aboutus = response.data[0];
        setIsLoading(false);
        if (!aboutus) {
          return;
        }
        setFormData({
          title: aboutus.title,
          frenchTitle: aboutus.frenchTitle,
          description: aboutus.description,
          frenchDescription: aboutus.frenchDescription,
          secondTitle: aboutus.secondTitle,
          secondFrenchTitle: aboutus.secondFrenchTitle,
          secondDescription: aboutus.secondDescription,
          secondFrenchDescription: aboutus.secondFrenchDescription,
          thirdTitle: aboutus.thirdTitle,
          thirdFrenchTitle: aboutus.thirdFrenchTitle,
          thirdDescription: aboutus.thirdDescription,
          thirdFrenchDescription: aboutus.thirdFrenchDescription,
          fourthTitle: aboutus.fourthTitle,
          fourthFrenchTitle: aboutus.fourthFrenchTitle,

          fourthDescription: aboutus.fourthDescription,
          fourthFrenchDescription: aboutus.fourthFrenchDescription,

          fifthTitle: aboutus.fifthTitle,
          fifthFrenchTitle: aboutus.fifthFrenchTitle,
          fifthDescription: aboutus.fifthDescription,
          fifthFrenchDescription: aboutus.fifthFrenchDescription,
          android_link: aboutus.android_link,
          ios_link: aboutus.ios_link,
          id: aboutus.id,
        });

        let temp1 = [];
        let temp2 = [];
        // let temp3 = [];
        // let temp4 = [];

        if (aboutus.image_name) {
          temp1.push({
            id: aboutus.id,
            path: `${process.env.REACT_APP_FILES_PATH}/adminAboutus/${aboutus.image_name}`,
          });
        }

        if (aboutus.second_image_name) {
          temp2.push({
            id: aboutus.id,
            path: `${process.env.REACT_APP_FILES_PATH}/adminAboutus/${aboutus.second_image_name}`,
          });
        }

        // if (aboutus.android_image_name) {
        //   temp3.push({
        //     id: aboutus.id,
        //     path: `${process.env.REACT_APP_FILES_PATH}/adminAboutus/${aboutus.android_image_name}`,
        //   });
        // }

        // if (aboutus.ios_image_name) {
        //   temp4.push({
        //     id: aboutus.id,
        //     path: `${process.env.REACT_APP_FILES_PATH}/adminAboutus/${aboutus.ios_image_name}`,
        //   });
        // }

        setViewedFiles(temp1);
        setViewedSecondFiles(temp2);
        // setViewedThirdFiles(temp3);
        // setViewedFourthFiles(temp4);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(`Error`);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/aboutus/deleteImage`,
            data: {id: id},
          })
            .then((response) => {
              toast.success(`Success`);
              getData();
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = files;
      let arr2 = viewedFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFiles(arr);
      setViewedFiles(arr2);
    }
  };

  const removeSecondImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/aboutus/deleteSecondImage`,
            data: {id: id},
          })
            .then((response) => {
              toast.success(`Success`);
              getData();
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = secondFiles;
      let arr2 = viewedSecondFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setSecondFiles(arr);
      setViewedSecondFiles(arr2);
    }
  };

  // const removeThirdImage = (id) => {
  //   // delete from server
  //   if (id > 0) {
  //     Swal.fire({
  //       title: "Please Confirm Your Action ?",
  //       showCancelButton: true,
  //       confirmButtonText: "Delete",
  //       icon: "warning",
  //     }).then((result) => {
  //       /* Read more about isConfirmed, isDenied below */
  //       if (result.isConfirmed) {
  //         axios({
  //           method: "post",
  //           url: `${process.env.REACT_APP_API}/admin/aboutus/deleteAndroidImage`,
  //           data: { id: id },
  //         })
  //           .then((response) => {
  //             toast.success(`Success`);
  //             getData();
  //             toast.success(`Success`);
  //           })
  //           .catch((error) => {
  //             toast.error(`Error`);
  //           });
  //       }
  //     });
  //   } else {
  //     let arr = thirdFiles;
  //     let arr2 = viewedThirdFiles;
  //     arr = arr.filter((v) => {
  //       return v.id != id;
  //     });
  //     arr2 = arr2.filter((v) => {
  //       return v.id != id;
  //     });
  //     setThirdFiles(arr);
  //     setViewedThirdFiles(arr2);
  //   }
  // };

  // const removeFourthImage = (id) => {
  //   // delete from server
  //   if (id > 0) {
  //     Swal.fire({
  //       title: "Please Confirm Your Action ?",
  //       showCancelButton: true,
  //       confirmButtonText: "Delete",
  //       icon: "warning",
  //     }).then((result) => {
  //       /* Read more about isConfirmed, isDenied below */
  //       if (result.isConfirmed) {
  //         axios({
  //           method: "post",
  //           url: `${process.env.REACT_APP_API}/admin/aboutus/deleteIosImage`,
  //           data: { id: id },
  //         })
  //           .then((response) => {
  //             toast.success(`Success`);
  //             getData();
  //             toast.success(`Success`);
  //           })
  //           .catch((error) => {
  //             toast.error(`Error`);
  //           });
  //       }
  //     });
  //   } else {
  //     let arr = fourthFiles;
  //     let arr2 = viewedFourthFiles;
  //     arr = arr.filter((v) => {
  //       return v.id != id;
  //     });
  //     arr2 = arr2.filter((v) => {
  //       return v.id != id;
  //     });
  //     setFourthFiles(arr);
  //     setViewedFourthFiles(arr2);
  //   }
  // };

  const handleChangeFile = async (originalFile) => {
    const file = await imageCompression(originalFile, options);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
      let temp2 = [];
      files.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFiles(temp2);

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleChangeSecondFile = async (originalFile) => {
    const file = await imageCompression(originalFile, options);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
      let temp2 = [];
      secondFiles.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setSecondFiles(temp2);

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedSecondFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedSecondFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  // const handleChangeThirdFile = async (originalFile) => {
  //   const file = await imageCompression(originalFile, options);
  //   const fileData = file;
  //   if (fileData.type.match("image.*")) {
  //     const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
  //     let temp2 = [];
  //     thirdFiles.forEach((element) => {
  //       temp2.push(element);
  //     });
  //     temp2.push({ id: randomId, file: fileData });
  //     setThirdFiles(temp2);

  //     const reader = new FileReader();
  //     reader.readAsDataURL(fileData);

  //     reader.onloadend = function (e) {
  //       const imagePath = reader.result;
  //       let temp = [];
  //       viewedThirdFiles.forEach((element) => {
  //         temp.push(element);
  //       });
  //       temp.push({ id: randomId, path: fileData, path: imagePath });
  //       setViewedThirdFiles(temp);
  //     };
  //   } else {
  //     toast("Only Images Can be Uploaded", {
  //       position: "top-right",
  //       type: "error",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //   }
  // };

  // const handleChangeFourthFile = async (originalFile) => {
  //   const file = await imageCompression(originalFile, options);
  //   const fileData = file;
  //   if (fileData.type.match("image.*")) {
  //     const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
  //     let temp2 = [];
  //     fourthFiles.forEach((element) => {
  //       temp2.push(element);
  //     });
  //     temp2.push({ id: randomId, file: fileData });
  //     setFourthFiles(temp2);

  //     const reader = new FileReader();
  //     reader.readAsDataURL(fileData);

  //     reader.onloadend = function (e) {
  //       const imagePath = reader.result;
  //       let temp = [];
  //       viewedFourthFiles.forEach((element) => {
  //         temp.push(element);
  //       });
  //       temp.push({ id: randomId, path: fileData, path: imagePath });
  //       setViewedFourthFiles(temp);
  //     };
  //   } else {
  //     toast("Only Images Can be Uploaded", {
  //       position: "top-right",
  //       type: "error",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //     });
  //   }
  // };

  const handleInputChange = (e) => {
    const {id, value} = e.target;

    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <AdminLayout>
      <div className="container" style={{marginBottom: 100}}>
        <div className="row admin-aboutus-row" style={{padding: 10, marginBottom: 50}}>
          <div style={{marginTop: 20}}>
            <h3>Hero Section 1</h3>
          </div>
          {!viewedFiles.length > 0 && (
            <FileUploader classes="w-100" handleChange={handleChangeFile} name="file" types={fileTypes}>
              <div
                className="w-100"
                style={{
                  minHeight: "25vh",
                  border: "2px dashed #ced4da",
                  padding: "10px",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <p>DROP YOUR IMAGE HERE</p>
              </div>
            </FileUploader>
          )}
          <div className="row mt-3">
            {viewedFiles?.map((imageData, index) => {
              return (
                <div className="col-sm-12" key={index}>
                  <img src={imageData.path} className="w-100 mt-3" />
                  <button
                    className="btn btn-danger"
                    style={{position: "absolute"}}
                    onClick={() => {
                      removeImage(imageData.id);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
          </div>
          <label htmlFor="Title">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            onChange={handleInputChange}
            value={formData.title}
          />
          {/* <label htmlFor="frenchTitle">French Title</label>
          <input
            type="text"
            className="form-control"
            id="frenchTitle"
            onChange={handleInputChange}
            value={formData.frenchTitle}
          /> */}
          <label htmlFor="name">Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="description"
            onChange={handleInputChange}
            value={formData.description}
          />
          {/* <label htmlFor="name">French Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="frenchDescription"
            onChange={handleInputChange}
            value={formData.frenchDescription}
          /> */}
          <div style={{marginTop: 50}}>
            <h3>Section 2</h3>
          </div>
          <label htmlFor="secondTitle">Second Title</label>
          <input
            type="text"
            className="form-control"
            id="secondTitle"
            onChange={handleInputChange}
            value={formData.secondTitle}
          />
          {/* <label htmlFor="secondFrenchTitle">Second French Title</label>
          <input
            type="text"
            className="form-control"
            id="secondFrenchTitle"
            onChange={handleInputChange}
            value={formData.secondFrenchTitle}
          /> */}
          <label htmlFor="secondDescription">Second Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="secondDescription"
            onChange={handleInputChange}
            value={formData.secondDescription}
          />
          {/* <label htmlFor="secondFrenchDescription">Second French Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="secondFrenchDescription"
            onChange={handleInputChange}
            value={formData.secondFrenchDescription}
          /> */}
          <div style={{marginTop: 50}}>
            <h3>Section 3</h3>
          </div>

          <label htmlFor="thirdTitle">Third Title</label>
          <input
            type="text"
            className="form-control"
            id="thirdTitle"
            onChange={handleInputChange}
            value={formData.thirdTitle}
          />

          <label htmlFor="thirdDescription">Third Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="thirdDescription"
            onChange={handleInputChange}
            value={formData.thirdDescription}
          />

          {/* <label htmlFor="thirdFrenchTitle">Third French Title</label>
          <input
            type="text"
            className="form-control"
            id="thirdFrenchTitle"
            onChange={handleInputChange}
            value={formData.thirdFrenchTitle}
          /> */}
          <div style={{marginTop: 50}}>
            <h3>Section 4</h3>
          </div>
          <label htmlFor="fourthTitle">Fourth Title</label>
          <input
            type="text"
            className="form-control"
            id="fourthTitle"
            onChange={handleInputChange}
            value={formData.fourthTitle}
          />
          <label htmlFor="fourthDescription">Fourth Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="fourthDescription"
            onChange={handleInputChange}
            value={formData.fourthDescription}
          />
          {/* 
          <label htmlFor="fourthFrenchTitle">Fourth French Title</label>
          <input
            type="text"
            className="form-control"
            id="fourthFrenchTitle"
            onChange={handleInputChange}
            value={formData.fourthFrenchTitle}
          /> */}
          <div style={{marginTop: 50}}>
            <h3>Section 5</h3>
          </div>
          <label htmlFor="fifthTitle">Fifth Title</label>
          <input
            type="text"
            className="form-control"
            id="fifthTitle"
            onChange={handleInputChange}
            value={formData.fifthTitle}
          />
          {/* <label htmlFor="fifthFrenchTitle">Fifth French Title</label>
          <input
            type="text"
            className="form-control"
            id="fifthFrenchTitle"
            onChange={handleInputChange}
            value={formData.fifthFrenchTitle}
          /> */}
          <label htmlFor="fifthDescription">Fifth Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="fifthDescription"
            onChange={handleInputChange}
            value={formData.fifthDescription}
          />
          {/* <label htmlFor="fifthFrenchDescription">Fifth French Description</label>
          <textarea
            type="text"
            rows="4"
            cols="50"
            className="form-control"
            id="fifthFrenchDescription"
            onChange={handleInputChange}
            value={formData.fifthFrenchDescription}
          /> */}
          {/* <div style={{marginTop: 50, marginBottom: 50}}>
            <h3>Mobile APP link</h3>
          </div> */}
          {/*           
          <div className="row">
            <div className={"col-sm-6"}>
              <label htmlFor="android_link">Andoid link</label>
              <input
                type="text"
                className="form-control"
                id="android_link"
                onChange={handleInputChange}
                value={formData.android_link}
              />
            </div>
            <div className={"col-sm-6"}>
              <label htmlFor="ios_link">IOS link</label>
              <input
                type="text"
                className="form-control"
                id="ios_link"
                onChange={handleInputChange}
                value={formData.ios_link}
              />
            </div>
          </div> */}
        </div>

        <div style={{marginTop: 50}}>
          <h3>Contact us image</h3>

          {!viewedSecondFiles.length > 0 && (
            <FileUploader classes="w-100" handleChange={handleChangeSecondFile} name="file" types={fileTypes}>
              <div
                className="w-100"
                style={{
                  minHeight: "25vh",
                  border: "2px dashed #ced4da",
                  padding: "10px",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <p>DROP YOUR CONTACT US IMAGE HERE</p>
              </div>
            </FileUploader>
          )}
          <div className="row mt-3">
            {viewedSecondFiles?.map((imageData, index) => {
              return (
                <div className="col-sm-12" key={index}>
                  <img src={imageData.path} className="w-100 mt-3" />
                  <button
                    className="btn btn-danger"
                    style={{position: "absolute"}}
                    onClick={() => {
                      removeSecondImage(imageData.id);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className={"row"}>
          <div className={"col-sm-12"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminHomePage;
