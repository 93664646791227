import React, {useState, useEffect} from "react";
import AdminLayout from "./AdminLayout";
import axios from "axios";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {FileUploader} from "react-drag-drop-files";
import {CircularProgress} from "@material-ui/core";
import imageCompression from "browser-image-compression";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const AdminSignUp = ({children, match, history}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const [viewedFiles, setViewedFiles] = useState([]);

  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const saveForm = () => {
    setSaveIsLoading(true);
    const submitFormData = new FormData();

    files?.map((fileData) => {
      submitFormData.append("files[]", fileData.file);
    });

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/admin/signup/add`,
      data: submitFormData,
    })
      .then((response) => {
        toast.success(`Success`);

        setSaveIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(`Error`);
        setSaveIsLoading(false);
      });
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/signup/get`)
      .then((response) => {
        let signup = response.data;
        setIsLoading(false);
        if (!signup) {
          return;
        }

        let temp1 = [];

        if (signup.image_name) {
          temp1.push({
            id: signup.id,
            path: `${process.env.REACT_APP_FILES_PATH}/signupImage/${signup.image_name}`,
          });
        }

        setViewedFiles(temp1);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error(`Error`);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/signup/delete`,
            data: {id: id},
          })
            .then((response) => {
              toast.success(`Success`);
              getData();
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = files;
      let arr2 = viewedFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFiles(arr);
      setViewedFiles(arr2);
    }
  };

  const handleChangeFile = async (originalFile) => {
    const file = await imageCompression(originalFile, options);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
      let temp = [];
      files.forEach((element) => {
        temp.push(element);
      });
      temp.push({id: randomId, file: fileData});
      setFiles(temp);

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <AdminLayout>
      <div className="container">
        <div className="row admin-signup-row" style={{marginBottom: 100}}>
          <div className={"col-sm-12"}>
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                Save
              </button>
            )}
          </div>

          {!viewedFiles.length > 0 && (
            <FileUploader classes="w-100" handleChange={handleChangeFile} name="file" types={fileTypes}>
              <div
                className="w-100"
                style={{
                  minHeight: "25vh",
                  border: "2px dashed #ced4da",
                  padding: "10px",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <p>DROP YOUR IMAGE HERE</p>
              </div>
            </FileUploader>
          )}
          <div className="row mt-3">
            {viewedFiles?.map((imageData, index) => {
              return (
                <div className="col-sm-2" key={index}>
                  <img src={imageData.path} className="w-100 mt-3" />
                  <button
                    className="btn btn-danger"
                    style={{position: "absolute"}}
                    onClick={() => {
                      removeImage(imageData.id);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};
export default AdminSignUp;
