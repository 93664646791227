import {CircularProgress} from "@material-ui/core";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import {Dialog, Slide, AppBar, Toolbar} from "@material-ui/core";
import {Link, useNavigate} from "react-router-dom";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminReports = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState("");
  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "reporter",
      label: "reporter",
    },
    {
      name: "message",
      label: "Message",
    },
    {
      name: "reported",
      label: "Reported",
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  viewReport(meta);
                }}
              >
                View
              </button>

              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteForm(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  const handleClose = () => {
    setVisible(false);
  };

  const openVendorProfile = (id, name) => {
    navigate(`/vendor/${name}`, {state: {id: id}});
  };

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/reports/get`)
      .then(function (response) {
        setData(response.data);

        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/reports/delete`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const viewReport = (meta) => {
    const id = meta.rowData[0];
    let dataSelected = data.filter((record) => record.id == id);
    setSelectedData(dataSelected[0]);

    setVisible(!visible);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Reports"} data={data} columns={columns} options={options} />

        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">Report Details</h4>
            </Toolbar>
          </AppBar>
          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <label>Reporter :</label>
                <h5>{selectedData.reporter}</h5>
                <h5>{selectedData.reporter_phone}</h5>
                <h5>{selectedData.reporter_email}</h5>
              </div>
              <div className={"col-sm-6"}>
                <label>Reported :</label>
                <a
                  href="javascript:void(0);"
                  onClick={() => openVendorProfile(selectedData.reported_id, selectedData.reported)}
                >
                  <h5>{selectedData.reported}</h5>
                </a>
              </div>
              <div className={"col-sm-6"}>
                <label>Reason :</label>
                <h5>{selectedData.message}</h5>
              </div>

              <div className={"col-sm-12"}>
                <label>Attachements :</label>
                <div className="report-images" style={{display: "flex", flexWrap: "wrap"}}>
                  {selectedData &&
                    selectedData.images &&
                    selectedData.images?.map((image) => (
                      <div className="report-image" style={{margin: 20}}>
                        <img
                          alt="report image"
                          src={`${process.env.REACT_APP_FILES_PATH}/reports/${image.image_name}`}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};

export default AdminReports;
