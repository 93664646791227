import {CircularProgress} from "@material-ui/core";
import CategoryTwoToneIcon from "@mui/icons-material/CategoryTwoTone";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ReportIcon from "@mui/icons-material/Report";
import SendIcon from "@mui/icons-material/Send";
import TwitterIcon from "@mui/icons-material/Twitter";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Rating from "@mui/material/Rating";
import {styled} from "@mui/material/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import {FileUploader} from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import axios from "axios";
import {generateForm} from "../../helpers/functions";
import React, {useEffect, useState, useRef} from "react";
import {useSelector} from "react-redux";
import {Carousel} from "react-responsive-carousel";
import {useLocation} from "react-router-dom";
import CommentLogo from "../../../src/assets/icons/comment.svg";
import DislikeLogo from "../../../src/assets/icons/dislike.svg";
import LikeLogo from "../../../src/assets/icons/like.svg";
import messageLogo from "../../../src/assets/icons/message.svg";
import noProfileImg from "../../../src/assets/images/avatar.jpg";
import FacebookLogo from "../../../src/assets/icons/facebook.png";
import InstaLogo from "../../../src/assets/icons/insta.png";
import TwitterLogo from "../../../src/assets/icons/twitter.png";
import LinkedinLogo from "../../../src/assets/icons/linkedin.png";
import {timeSince} from "../../helpers/functions";
import FrontendNav from "./FrontendNav";
import {toast} from "react-toastify";

import "./styles/FrontendCustomerProfile.css";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const Item = styled(Paper)(({theme}) => ({
  backgroundColor: "white",
  ...theme.typography.body2,
  padding: "10px",
  textAlign: "center",

  marginBottom: 20,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,

  boxShadow: 24,
};
const modalStyle = {
  margin: "auto",
  width: "80%",
  height: "100%",
  border: "2px solid #D62221",
};
const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const FrontendCustomerProfile = () => {
  const messagesBox = useRef(null);
  const lan = useSelector((state) => state.currentLanguage);
  const currentUser = useSelector((state) => state.currentUser);
  const [likeUnlikeLoading, setLikeUnlikeLoading] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [replyVisible, setReplyVisible] = useState("");
  const [selectedComment, setSelectedComment] = useState("");
  const [postComment, setPostComment] = useState("");
  const [commentVisible, setCommentVisible] = useState(false);
  const [commentReply, setCommentReply] = useState("");
  const [selectedPost, setSelectedPost] = useState("");
  const [album, setAlbum] = useState("");
  const [loading, setLoading] = useState(false);

  const scrollToBottom = () => {
    messagesBox.current?.scrollTo({
      top: messagesBox.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleOpen = () => {
    getChats();
    if (!currentUser.user) {
      let msg = lan == "en" ? "Please Sign in to chat" : "Veuillez vous connecter pour discuter";
      alert(msg);
      return;
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleOpenReport = () => {
    if (!currentUser.user) {
      let msg = lan == "en" ? "Please Sign in to report" : "Veuillez vous connecter pour signaler";
      alert(msg);
      return;
    }
    setOpenReport(true);
  };
  const handleCloseReport = () => setOpenReport(false);

  const [files, setFiles] = useState([]);
  const [viewedFiles, setViewedFiles] = useState([]);
  const [customer, setCustomer] = useState("");

  const [allMessages, setAllMessages] = useState("");
  const [previousWork, setpreviousWork] = useState("");
  const [previousPosts, setPreviousPosts] = useState("");
  const [openReport, setOpenReport] = useState(false);
  const [open, setOpen] = useState(false);
  const [chatId, setChatId] = useState("");
  const [message, setMessage] = useState("");
  const [reportMessage, setReportMessage] = useState("");
  const {state} = useLocation();
  const {id} = state || {};

  const getCustomerPosts = () => {
    axios
      .get(`${process.env.REACT_APP_API}/noAuthApi/getCustomerPosts`, {
        params: {
          customer_id: id,
          user_id: currentUser.user && currentUser.user.id ? currentUser.user.id : "",
        },
      })
      .then(function (response) {
        setPreviousPosts(response.data);
      });
  };

  const getCustomerData = () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_API}/noAuthApi/getCustomer/${id}`, {}).then(function (response) {
      setCustomer(response.data);
      setLoading(false);

      axios.get(`${process.env.REACT_APP_API}/noAuthApi/getPosts/${id}`, {}).then(function (response) {
        setpreviousWork(response.data);
      });
    });
  };

  const getChats = () => {
    axios
      .get(`${process.env.REACT_APP_API}/mobile/chats/getChats`, {})
      .then((res) => {
        let chats = res.data;
        const filteredChats = chats.filter((chat) => chat.customer_id == id);

        filteredChats?.map((chat) => {
          axios
            .get(`${process.env.REACT_APP_API}/mobile/chats/getMessages`, {
              params: {
                chat_id: chat.id.toString(),
              },
            })

            .then((res) => setAllMessages(res.data))
            .catch((err) => console.log(err));
        });
      })
      .catch((err) => console.log(err));
  };

  const sendMessage = () => {
    const d = new Date();
    let datetime = d.toLocaleString();
    const newMessage = {
      message,
      chat_id: chatId,
      created_at: datetime,
      user_id: currentUser.user.id,
    };

    setAllMessages((previousMessages) => [...previousMessages, newMessage]);

    if (chatId) {
      const msgObj = {message: message, chat_id: chatId};
      axios
        .post(`${process.env.REACT_APP_API}/mobile/chats/addMessage`, msgObj)
        .then((res) => console.log(res.data))
        .catch((err) => console.log(err));
    } else {
      let target = {target_user_id: id};
      axios
        .post(`${process.env.REACT_APP_API}/mobile/chats/addChat`, target)
        .then((res) => {
          setChatId(res.data.id.toString());
          const msgObj = {message: message, chat_id: res.data.id.toString()};
          axios
            .post(`${process.env.REACT_APP_API}/mobile/chats/addMessage`, msgObj)
            .then((res) => console.log(res.data))
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    }
    setMessage("");
    getChats();
  };

  const sendReport = () => {
    let formData = {message: reportMessage, reported_id: id};
    const submitFormData = generateForm(formData);
    files?.map((fileData) => {
      submitFormData.append("files[]", fileData.file);
    });

    axios({
      method: "post",
      accept: "any",
      url: `${process.env.REACT_APP_API}/mobile/otherApis/report`,
      data: submitFormData,
    })
      .then((res) => {
        console.log(res.data);
        setReportMessage("");
        setOpenReport(false);

        let msg = lan == "en" ? "Successfully reported" : "Signalé avec succès";

        toast.success(msg);
      })
      .catch((error) => {
        toast.error(error.response.data.error);
        console.log(error);
      });
  };
  const likeUnlikePost = (postId, liked) => {
    if (!currentUser.user) {
      let msg = lan == "en" ? "Please Sign to be able to like" : "Veuillez signer pour pouvoir aimer";

      alert(msg);
      return;
    }

    if (likeUnlikeLoading) return;

    setLikeUnlikeLoading(true);

    const post = {post_id: postId};

    if (liked == 0) {
      axios
        .post(`${process.env.REACT_APP_API}/mobile/posts/like`, post)
        .then(function (response) {
          getCustomerPosts();
          setLikeUnlikeLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLikeUnlikeLoading(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/mobile/posts/unLike`, post)
        .then(function (response) {
          getCustomerPosts();
          setLikeUnlikeLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLikeUnlikeLoading(false);
        });
    }
  };

  const showComment = (id) => {
    if (!currentUser.user) {
      let msg = lan == "en" ? "Please Sign to be able to comment" : "Veuillez signer pour pouvoir commenter";
      alert(msg);
      return;
    }

    setSelectedPost(id);
    setCommentVisible(true);
  };
  const likeUnlikeComment = (commentId, liked) => {
    if (!currentUser.user) {
      let msg = lan == "en" ? "Please Sign to be able to like" : "Veuillez signer pour pouvoir aimer";
      alert(msg);
      return;
    }

    if (likeUnlikeLoading) return;

    setLikeUnlikeLoading(true);

    const comment = {comment_id: commentId};

    if (liked == 0) {
      axios
        .post(`${process.env.REACT_APP_API}/mobile/posts/likeComment`, comment)
        .then(function (response) {
          getCustomerPosts();
          setLikeUnlikeLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLikeUnlikeLoading(false);
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API}/mobile/posts/unlikeComment`, comment)
        .then(function (response) {
          getCustomerPosts();
          setLikeUnlikeLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLikeUnlikeLoading(false);
        });
    }
  };

  const showReply = (id) => {
    setSelectedComment(id);
    setReplyVisible(true);
  };

  const addReply = (commentId) => {
    const reply = {original_comment_id: commentId, comment: commentReply};
    axios
      .post(`${process.env.REACT_APP_API}/mobile/posts/addCommentReply`, reply)
      .then(function (response) {
        getCustomerPosts();
        setReplyVisible(false);
        setCommentReply("");
      })
      .catch((err) => console.log(err));
  };
  const addComment = (postId) => {
    setCommentLoading(true);
    const comment = {post_id: postId.toString(), comment: postComment};
    axios
      .post(`${process.env.REACT_APP_API}/mobile/posts/addComment`, comment)
      .then(function (response) {
        getCustomerPosts();
        setCommentVisible(false);
        setPostComment("");
        setCommentLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCommentLoading(false);
      });
  };

  const removeImage = (id) => {
    let arr = files;
    let arr2 = viewedFiles;
    arr = arr.filter((v) => {
      return v.id != id;
    });
    arr2 = arr2.filter((v) => {
      return v.id != id;
    });
    setFiles(arr);
    setViewedFiles(arr2);
  };

  const handleChangeFiles = async (originalFile) => {
    const file = await imageCompression(originalFile, options);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      let temp2 = [];
      files.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFiles(temp2);
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  useEffect(() => {
    getCustomerPosts();
    getCustomerData();
  }, [currentUser]);

  useEffect(() => {
    scrollToBottom();
  }, [allMessages]);

  return (
    <FrontendNav>
      <div className="mt-3 customer-profile-page">
        <div className="container small-container">
          <div className="row">{loading ? <CircularProgress /> : <div> </div>}</div>

          <div className="row">
            {customer && (
              <Grid container spacing={2} className="customer-section">
                <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Item className="profile-top-row-item item-box">
                    <div className="profile-top-row-left">
                      <div className="profile-img">
                        <img
                          src={
                            customer.profile_image_name
                              ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${customer.profile_image_name}`
                              : noProfileImg
                          }
                        />

                        <div></div>
                      </div>
                      <div className="profile-info">
                        <h4>{customer.name}</h4>
                        <p style={{margin: 0}}>{customer.address}</p>
                        <p>{customer.phone}</p>

                        <p>{customer.profile_description}</p>
                      </div>
                    </div>
                    <div className="profile-top-row-right" style={{maxHeight: 100, margin: "auto"}}>
                      <button className="user-chat-btn" onClick={handleOpen}>
                        <span>
                          <img width={80} height={80} src={messageLogo} style={{marginRight: 10}} />
                        </span>
                      </button>
                    </div>
                  </Item>
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={{margin: "auto"}}>
                  {previousPosts &&
                    customer &&
                    previousPosts?.map((post, index) => (
                      <Item className="profile-post-item" key={index}>
                        <div className="profile-post-user">
                          <img
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: "50%",
                            }}
                            src={
                              customer.profile_image_name
                                ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${customer.profile_image_name}`
                                : noProfileImg
                            }
                          />
                          <span>
                            {" "}
                            <h5>{customer.first_name}</h5> <i>{timeSince(post.created_at)}</i>
                          </span>
                        </div>
                        <div className="profile-post-images">
                          {post.images?.map((img) => (
                            <div>
                              <img
                                src={`${process.env.REACT_APP_FILES_PATH}/posts/${img}`}
                                onClick={() => {
                                  setOpen(true);
                                  setAlbum(post.images);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="profile-post-desc">
                          <p>{post.description}</p>
                        </div>
                        {/* <div className="profile-post-social">
                          <hr />
                          <div className="profile-post-socialbtns">
                            <button onClick={() => likeUnlikePost(post.id, post.liked)}>
                              {post.liked == 1 ? (
                                <span>
                                  <img width={30} height={30} src={DislikeLogo} style={{marginRight: 10}} />
                                </span>
                              ) : (
                                <span>
                                  <img width={30} height={30} src={LikeLogo} style={{marginRight: 10}} />
                                </span>
                              )}
                              {post.likes}
                              {post.likes == 1 ? (
                                <>{lan == "en" ? " Like" : " aime"} </>
                              ) : (
                                <>{lan == "en" ? " Likes" : " aime"} </>
                              )}
                            </button>
                            <button onClick={() => showComment(post.id)}>
                              <span>
                                <img width={30} height={30} src={CommentLogo} style={{marginRight: 10}} />
                              </span>
                              {post.comments.length}
                              {post.comments.length == 1 ? (
                                <>{lan == "en" ? " comment" : " commentaire"}</>
                              ) : (
                                <>{lan == "en" ? " comments" : " commentaires"}</>
                              )}
                            </button>
                          </div>
                          <hr />
                        </div> */}
                        {/* <div className="profile-post-comments">
                          {post.comments &&
                            post.comments?.map((c) => (
                              <>
                                <div className="profile-post-comment">
                                  <div className="profile-post-comment-user">
                                    <img
                                      style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: "50%",
                                      }}
                                      src={
                                        c.profile_image_name
                                          ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${c.profile_image_name}`
                                          : noProfileImg
                                      }
                                    />
                                  </div>
                                  <div className="profile-post-comment-content">
                                    <div className="profile-post-comment-text">
                                      <h5>{c.name}</h5>
                                      <p>{c.comment}</p>
                                    </div>
                                    <div className="profile-post-comment-social">
                                      <button
                                        onClick={() => likeUnlikeComment(c.id, c.liked)}
                                        style={{
                                          color: c.liked == 1 ? "#D62221" : "",
                                        }}
                                      >
                                        <span style={{marginRight: "5px"}}>{c.likes}</span>
                                        {c.liked == 0 ? (
                                          <>{lan == "en" ? "Like" : "Aime"} </>
                                        ) : (
                                          <>{lan == "en" ? "Dislike" : "Aime pas"}</>
                                        )}
                                      </button>
                                      <button onClick={() => showReply(c.id)}>
                                        {lan == "en" ? "Reply" : "Répondre"}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {replyVisible && c.id == selectedComment && currentUser.user && (
                                  <div className="profile-post-comment-reply">
                                    <div className="profile-post-comment-reply-user">
                                      <img
                                        style={{
                                          width: 50,
                                          height: 50,
                                          borderRadius: "50%",
                                        }}
                                        src={
                                          currentUser.user.profile_image_name
                                            ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${currentUser.user.profile_image_name}`
                                            : noProfileImg
                                        }
                                      />
                                    </div>

                                    <input
                                      value={commentReply}
                                      onChange={(e) => setCommentReply(e.target.value)}
                                      className="profile-post-comment-post"
                                    />
                                    <button onClick={() => addReply(c.id)}>
                                      <SendIcon style={{fontSize: "3em"}} />
                                    </button>
                                  </div>
                                )}

                                {c.replies &&
                                  c.replies?.map((reply) => (
                                    <>
                                      <div className="profile-post-comment-reply">
                                        <div className="profile-post-comment-reply-user">
                                          <img
                                            style={{
                                              width: 50,
                                              height: 50,
                                              borderRadius: "50%",
                                            }}
                                            src={
                                              reply.profile_image_name
                                                ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${reply.profile_image_name}`
                                                : noProfileImg
                                            }
                                          />
                                        </div>
                                        <div className="profile-post-comment-reply-content">
                                          <div className="profile-post-comment-reply-text">
                                            <h5>{reply.name}</h5>
                                            <p>{reply.comment}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                              </>
                            ))}
                          {currentUser.user && (
                            <div className="profile-post-comment">
                              <div className="profile-post-comment-user">
                                <img
                                  style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: "50%",
                                  }}
                                  src={
                                    currentUser.user.profile_image_name
                                      ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${currentUser.user.profile_image_name}`
                                      : noProfileImg
                                  }
                                />
                              </div>

                              <input
                                value={postComment}
                                onChange={(e) => setPostComment(e.target.value)}
                                className="profile-post-comment-post"
                                placeholder={
                                  lan == "en" ? "Write a comment ..." : "Écrire un commentaire ..."
                                }
                              />
                              {commentLoading ? (
                                <div style={{padding: 10, textAlign: "center"}}>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <button onClick={() => addComment(post.id)}>
                                  <SendIcon style={{fontSize: "3em"}} />
                                </button>
                              )}
                            </div>
                          )}
                        </div> */}
                        <Modal open={open} onClose={handleClose}>
                          <Box sx={modalStyle}>
                            <Carousel
                              dynamicHeight={false}
                              showThumbs={false}
                              swipeable={true}
                              autoPlay={true}
                              infiniteLoop={true}
                              interval={5000}
                            >
                              {album &&
                                album?.map((photo, index) => {
                                  return (
                                    <div key={index}>
                                      <img
                                        style={{
                                          maxHeight: "100vh",
                                          maxWidth: "100%",
                                        }}
                                        src={`${process.env.REACT_APP_FILES_PATH}/posts/${photo}`}
                                      />
                                    </div>
                                  );
                                })}
                            </Carousel>
                          </Box>
                        </Modal>
                      </Item>
                    ))}
                </Grid>

                <Modal open={open} onClose={handleClose}>
                  <Box sx={boxStyle}>
                    <div className="chat-box-user-info">
                      <img
                        style={{
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                        }}
                        src={
                          customer.profile_image_name
                            ? `${process.env.REACT_APP_FILES_PATH}/userProfiles/${customer.profile_image_name}`
                            : noProfileImg
                        }
                      />
                      <h5>{customer.name}</h5>
                    </div>
                    <div className="chat-box" ref={messagesBox}>
                      {allMessages &&
                        allMessages?.map((chat) => (
                          <>
                            <p className={chat.user_id == currentUser.user.id ? "user-chat" : ""}>
                              {chat.message}
                            </p>
                            <p
                              className={
                                chat.user_id == currentUser.user.id ? "msg-date user-chat" : "msg-date"
                              }
                            >
                              {chat.created_at}
                            </p>
                          </>
                        ))}
                    </div>
                    <TextareaAutosize
                      aria-label="empty textarea"
                      minRows={2}
                      placeholder={lan == "en" ? " type your message here" : " tapez votre message ici"}
                      style={{width: "100%"}}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                      className="send-message-btn"
                      onClick={sendMessage}
                      style={{width: "100%", marginTop: 20, height: 40}}
                      variant="outlined"
                    >
                      {lan == "en" ? "SEND MESSAGE" : "ENVOYER LE MESSAGE"}
                    </Button>
                  </Box>
                </Modal>

                <Modal open={openReport} onClose={handleCloseReport}>
                  <Box sx={boxStyle}>
                    <div className="report-user-box">
                      <h5>
                        {" "}
                        {lan == "en" ? "REPORT & BLOCK" : "SIGNALER ET BLOQUER"} {customer.name}
                      </h5>
                    </div>

                    <TextareaAutosize
                      aria-label="empty textarea"
                      minRows={5}
                      placeholder="What is the reason ?"
                      style={{width: "100%"}}
                      value={reportMessage}
                      onChange={(e) => setReportMessage(e.target.value)}
                    />
                    <div className="project-images">
                      {viewedFiles &&
                        viewedFiles?.map((imageData, index) => {
                          return (
                            <div className="uploaded-project-image" key={index}>
                              <img src={imageData.path} />
                              <button
                                // className="btn btn-danger"
                                style={{
                                  position: "absolute",
                                  left: 0,
                                  width: 20,
                                  height: 20,
                                  backgroundColor: "red",
                                  color: "white",
                                  border: "none",
                                }}
                                onClick={() => {
                                  removeImage(imageData.id);
                                }}
                              >
                                X
                              </button>
                            </div>
                          );
                        })}
                      <FileUploader
                        multiple={false}
                        handleChange={handleChangeFiles}
                        name="file"
                        types={fileTypes}
                      >
                        <div className="project-image-uploader">
                          <h3>+</h3>
                        </div>
                      </FileUploader>
                    </div>

                    <Button
                      className="send-report-btn"
                      onClick={sendReport}
                      style={{width: "100%", marginTop: 20, height: 40}}
                      variant="outlined"
                    >
                      SEND REPORT
                    </Button>
                  </Box>
                </Modal>
              </Grid>
            )}
          </div>
        </div>
      </div>
    </FrontendNav>
  );
};

export default FrontendCustomerProfile;
