import React, {useState, useEffect} from "react";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import {toast} from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import {Dialog, Slide, AppBar, Toolbar, CircularProgress} from "@material-ui/core";
import {FileUploader} from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";
import AdminLayout from "./AdminLayout";
import {generateForm} from "../../helpers/functions";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
const fileTypes = ["JPG", "PNG", "JPEG"];
const options = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const AdminCategories = ({children, match, history}) => {
  const [data, setData] = useState([]);
  const [viewedFile, setViewedFile] = useState([]);
  const [file, setFile] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    frenchName: "",
    description: "",
    frenchDescription: "",

    keywords: "",

    is_commercial_contracting: false,
    requires_license: false,
    id: -1,
  });
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/categories/get`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const addForm = () => {
    setVisible(!visible);
    resetForm();
  };

  const updateFrom = (meta) => {
    resetForm();
    const id = meta.rowData[0];
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/categories/getById/${id}`,
    })
      .then((response) => {
        setFormData({
          name: response.data.name,
          frenchName: response.data.frenchName,
          description: response.data.description,
          frenchDescription: response.data.frenchDescription,

          keywords: response.data.keywords,

          is_commercial_contracting: response.data.is_commercial_contracting === 1 ? true : false,
          requires_license: response.data.requires_license === 1 ? true : false,
          id: id,
        });

        let temp1 = [];
        response.data.iconImages.forEach((element) => {
          temp1.push({
            id: element.id,
            path: `${process.env.REACT_APP_FILES_PATH}/adminCategories/${element.icon_name}`,
          });
        });
        setViewedFile(temp1);

        setVisible(!visible);
      })
      .catch((error) => {
        toast.error(`Error`);
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    if (formData.id > 0) {
      const submitFormData = generateForm(formData);

      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });

      axios({
        method: "post",
        url: `${process.env.REACT_APP_API}/admin/categories/update`,
        data: submitFormData,
      })
        .then((response) => {
          toast.success(`Updated successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    } else {
      const submitFormData = generateForm(formData);

      file?.map((fileData) => {
        submitFormData.append("file[]", fileData.file);
      });
      axios({
        method: "post",
        accept: "any",
        url: `${process.env.REACT_APP_API}/admin/categories/add`,
        data: submitFormData,
      })
        .then((response) => {
          //setCurrentId(response.data.serial)
          toast.success(`Inserted successfully`);
          setSaveIsLoading(false);
          setVisible(!visible);
          getData();
        })
        .catch((error) => {
          setSaveIsLoading(false);
          toast.error(`Error While Saving`);
        });
    }
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/categories/delete`,
          data: {id: id},
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };
  const removeImage = (id) => {
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/categories/deleteIcon`,
            data: {id: id},
          })
            .then((response) => {
              let arr = file;
              let arr2 = viewedFile;
              arr = arr.filter((v) => {
                return v.id != id;
              });
              arr2 = arr2.filter((v) => {
                return v.id != id;
              });
              setFile(arr);
              setViewedFile(arr2);
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = file;
      let arr2 = viewedFile;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFile(arr);
      setViewedFile(arr2);
    }
  };
  const handleInputChange = (e) => {
    const {
      id,
      name,
      frenchName,
      description,
      frenchDescription,

      value,
      checked,
      type,
    } = e.target;
    if (type === "checkbox") {
      setFormData({
        ...formData,
        [id]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [id]: value,
      });
    }
  };

  const handleChangeFile = async (originalFile) => {
    const imgFile = await imageCompression(originalFile, options);
    const fileData = imgFile;
    if (fileData.type.match("image.*")) {
      const randomId = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      let temp2 = [];
      file.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({id: randomId, file: fileData});
      setFile(temp2);
      const reader = new FileReader();
      reader.readAsDataURL(fileData);
      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFile.forEach((element) => {
          temp.push(element);
        });
        temp.push({id: randomId, path: fileData, path: imagePath});
        setViewedFile(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      frenchName: "",
      description: "",
      keywords: "",
      frenchDescription: "",
      is_commercial_contracting: false,
      requires_license: false,
      id: -1,
    });

    setViewedFile([]);
    setFile([]);
  };

  const handleClose = () => {
    setVisible(false);
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "name",
      label: "Name",
    },
    // {
    //   name: "frenchName",
    //   label: "French Name",
    // },
    {
      name: "description",
      label: "Description",
    },
    // {
    //   name: "frenchDescription",
    //   label: "French Description",
    // },
    {
      name: "is_commercial_contracting",
      label: "Commercial Contracting",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value === 1 ? true : false}
            />
          );
        },
      },
    },
    {
      name: "requires_license",
      label: "License required",
      options: {
        customBodyRender: (value) => {
          return (
            <input
              disabled
              className="form-check-input"
              type="checkbox"
              readOnly
              checked={value === 1 ? true : false}
            />
          );
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-danger mx-1"
                onClick={() => {
                  deleteForm(meta);
                }}
              >
                Delete
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "There is no matching data to display",
      },
    },
  };

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable title={"Categories"} data={data} columns={columns} options={options} />
        <Dialog
          fullScreen
          open={visible}
          onClose={handleClose}
          TransitionComponent={Transition}
          style={{zIndex: 2235}}
        >
          <AppBar className="position-relative">
            <Toolbar>
              <button type="button" className="close-modal-button" onClick={handleClose}>
                &times;
              </button>
              <h4 className="text-white mx-3 my-0">{formData.id > 0 ? "Update" : "Add"}</h4>
            </Toolbar>
          </AppBar>

          <div style={{padding: "25px"}}>
            <div className="row">
              <div className={"col-sm-6"}>
                <div className="form-group">
                  {!viewedFile.length > 0 && (
                    <FileUploader
                      multiple={false}
                      classes="w-100"
                      handleChange={handleChangeFile}
                      name="file"
                      types={fileTypes}
                    >
                      <div
                        className="w-100"
                        style={{
                          minHeight: "25vh",
                          border: "2px dashed #ced4da",
                          padding: "10px",
                          textAlign: "center",
                          marginTop: "1rem",
                        }}
                      >
                        <p>DROP YOUR ICON HERE</p>
                      </div>
                    </FileUploader>
                  )}
                  {viewedFile?.map((imageData, index) => {
                    return (
                      <div className="col-sm-2" key={index}>
                        <img src={imageData.path} className="w-100 mt-3" />
                        <button
                          className="btn btn-danger"
                          style={{position: "absolute"}}
                          onClick={() => {
                            removeImage(imageData.id);
                          }}
                        >
                          X
                        </button>
                      </div>
                    );
                  })}
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    onChange={handleInputChange}
                    value={formData.name}
                  />

                  {/* <label htmlFor="name">French Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="frenchName"
                    onChange={handleInputChange}
                    value={formData.frenchName}
                  /> */}

                  <label htmlFor="name">Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    onChange={handleInputChange}
                    value={formData.description}
                  />

                  <label htmlFor="name">Keywords</label>
                  <input
                    type="text"
                    className="form-control"
                    id="keywords"
                    onChange={handleInputChange}
                    value={formData.keywords}
                  />

                  {/* <label htmlFor="name">French Description</label>
                  <input
                    type="text"
                    className="form-control"
                    id="frenchDescription"
                    onChange={handleInputChange}
                    value={formData.frenchDescription}
                  /> */}
                </div>
              </div>
              <div className={"col-sm-6"}>
                <div class="form-check mt-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="is_commercial_contracting"
                    checked={formData.is_commercial_contracting}
                    onChange={handleInputChange}
                    value={formData.is_commercial_contracting || ""}
                  />
                  <label class="form-check-label" for="is_commercial_contracting">
                    Commercial Contracting
                  </label>
                </div>
                <div class="form-check mt-5">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="requires_license"
                    checked={formData.requires_license}
                    onChange={handleInputChange}
                    value={formData.requires_license || ""}
                  />
                  <label class="form-check-label" for="requires_license">
                    License required
                  </label>
                </div>
              </div>

              <div className={"col-sm-12 mt-5"}>
                {saveIsLoading ? (
                  <CircularProgress />
                ) : (
                  <button className="btn btn-md btn-primary float-end" onClick={saveForm}>
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </AdminLayout>
  );
};
export default AdminCategories;
