import React, {useEffect, useState} from "react";

import CustomDialog from "src/Components/CustomDialog";
import PropTypes from "prop-types";

const CustomFilter = (props) => {
  return (
    <CustomDialog
      open={props.openDialog}
      onClose={props.handleClose}
      onSave={props.handleSave}
      handleclear={props.handleclear}
    >
      <div className="row">
        <div className={"col-sm-12 col-md-6"}>
          <div className="form-group">
            <div className="dateView">
              <label style={{fontSize: "18px", marginTop: "10px", marginRight: "10px"}} htmlFor="fromDate">
                From Date
              </label>
              <input
                type="date"
                style={{
                  width: 200,
                  height: 50,
                  border: "1px solid #D3D3D3",
                  borderRadius: 5,
                }}
                id="fromDate"
                value={props.filterForm.fromDate}
                onChange={props.handleChangeInputFilter}
              />
            </div>
          </div>
        </div>

        <div className={"col-sm-12 col-md-6"}>
          <div className="form-group">
            <div className="dateView">
              <label style={{fontSize: "18px", marginTop: "10px", marginRight: "10px"}} htmlFor="toDate">
                To Date
              </label>
              <input
                type="date"
                style={{
                  width: 200,
                  height: 50,
                  border: "1px solid #D3D3D3",
                  borderRadius: 5,
                }}
                id="toDate"
                value={props.filterForm.toDate}
                onChange={props.handleChangeInputFilter}
              />
            </div>
          </div>
        </div>
      </div>
    </CustomDialog>
  );
};
CustomFilter.propTypes = PropTypes.shape({
  params: PropTypes.shape({
    openDialog: PropTypes.node,
    handleClose: PropTypes.node,
    filterForm: PropTypes.node,
    handleChangeInputFilter: PropTypes.func,
    customOnChange: PropTypes.func,
    handleSave: PropTypes.func,
    handleclear: PropTypes.func,

    warehouses: PropTypes.any,
    sections: PropTypes.any,
    shelves: PropTypes.any,
    categories: PropTypes.any,
  }).isRequired,
}).isRequired;

export default CustomFilter;
